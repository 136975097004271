import React, {useState} from "react";
import {RolesMultiSelect} from "./RolesMultiSelect";
import {EditOutlined} from "@ant-design/icons";
import {UserProfile} from "../hooks/useUserProfile";

export const RolesCell: React.FC<{
    defaultValues: string[],
    rowId: number
    userProfile: UserProfile | undefined
}> = ({defaultValues, rowId, userProfile}) => {
    
    const [editing, setEditing] = useState(false)
    
    const action = () => {
        setEditing(!editing)
    }
    
    return <span style={{display: "flex"}}>
            <div style={{width: 220}}>
                <RolesMultiSelect editing={editing}
                                  setEditing={setEditing}
                                  defaultValues={defaultValues}
                                  onChange={(value: string[]) => undefined} //Onchange gets managed inside of the RolesMultiselecd
                                  rowId={rowId}
                                  userProfile={userProfile}
                />
            </div>
            <div style={{marginLeft: 5}}>
               <EditOutlined onClick={action}/>
            </div>
        </span>
};