import buildUrl from "build-url";

import {TyreFilterState} from "./types";
import {getFilterQuery} from "../gridFiltering";

export const TYRE_FILTER_SESSION_KEY = 'TYRE_FILTERS';
export const DEFAULT_SEARCH_IN       = ["name", "license", "comment", "pile", "row", 'storage']
export const DEFAULT_TYRE_STATUS     = ["storage"];

export const DEFAULT_TYRE_FILTERS = {
    searchIn:       DEFAULT_SEARCH_IN,
    selectedStatus: DEFAULT_TYRE_STATUS
};

export function toQueryString(filter: TyreFilterState, sort?: string): string {
    return buildUrl('', {
        queryParams: {
            ...(sort ? {sort} : {}),
            'filter[location]':      filter.selectedLocations || [],
            'filter[season]':        filter.selectedSeasons || [],
            'filter[status]':        filter.selectedStatus || [],
            'filter[intake_before]': filter.intakeDateBefore || '',
            'filter[intake_after]':  filter.intakeDateAfter || '',
            'filter[special]':       getFilterQuery(filter) || '',
            'filter[trashed]':       filter.trashed ? "only" : "false" || '',
        },
    });
}
