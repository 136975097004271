import React from "react";
import {Layout} from "antd";

import {CustomerFilters} from "./CustomerFilters";
import CustomerGrid from "./CustomerGrid";
import {FilterFrame} from "../FilterFrame";
import {useSessionState} from "../hooks/useSessionState";
import {CustomerFilterState} from "./types";
import {CUSTOMER_FILTER_SESSION_KEY, DEFAULT_SEARCH_IN} from "./customerFiltering";


const Customer: React.FC = () => {

    const [filters, setFilters] = useSessionState<CustomerFilterState>(CUSTOMER_FILTER_SESSION_KEY, {searchIn: DEFAULT_SEARCH_IN});

    return <Layout>
        <FilterFrame>
            <CustomerFilters value={filters} onChange={setFilters}/>
        </FilterFrame>
        <Layout>
            <CustomerGrid filters={filters}/>
        </Layout>
    </Layout>
};
export default Customer
