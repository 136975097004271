import {ColumnsType} from "antd/es/table";
import {ApiTyre} from "./types";
import {hasEditableFields} from "../common/headerCell";
import {availableSeasons, seasonLabels, SeasonLiteral} from "../common/data";
import {ConnectedDropDownCell} from "../TableComponents/ConnectedDropDownCell";
import {ConnectedEditableCell} from "../TableComponents/ConnectedEditableCell";
import React from "react";

export const tyreDetailsColumns = (editable: boolean, reload?: () => void): ColumnsType<ApiTyre> => [
  {
    ...(editable ? hasEditableFields : {}),
    key:       'season',
    dataIndex: 'season',
    title:     'Seizoen',
    sorter:    editable,
    width:     editable ? 145 : 70,
    render(season: SeasonLiteral, tyre) {
      return editable ?
        <ConnectedDropDownCell
          model={'tyres'}
          rowId={tyre.id}
          field={'season'}
          availableValues={availableSeasons}
          initialValue={season}
          getLabel={value => (seasonLabels as any)[value]}
        /> :
        (seasonLabels)[tyre.season]
    }
  }, {
    ...hasEditableFields,
    key:       'row',
    dataIndex: 'row',
    title:     'Rij',
    sorter:    editable,
    width:     60,
    render(_, tyre) {
      return <ConnectedEditableCell model={"tyres"} rowId={tyre.id}
                                    onChange={() => {
                                      if (reload) {
                                        reload();
                                      }
                                    }}
                                    field={'row'} initialValue={tyre.row?.toString() || ''}
      />
    }
  }, {
    ...(editable ? hasEditableFields : {}),
    key:       'pile',
    dataIndex: 'pile',
    title:     'Stapel',
    sorter:    editable,
    width:     70,
    render(_, tyre) {
      return <ConnectedEditableCell model={"tyres"} rowId={tyre.id} field={'pile'}
                                    onChange={() => {
                                      if (reload) {
                                        reload();
                                      }
                                    }}
                                    initialValue={tyre.pile?.toString() || ''}
      />
    }
  }, {
    ...hasEditableFields,
    key:       'tread_depth',
    dataIndex: 'tread_depth',
    title:     'Profiel',
    width:     100,
    render(_, tyre) {
      return <ConnectedEditableCell model={"tyres"} rowId={tyre.id} field={'tread_depth'}
                                    initialValue={tyre.tread_depth?.toString() || ''}
      />
    }
  }, {
    ...hasEditableFields,
    key:       'details',
    dataIndex: 'details',
    title:     'Details',
    width:     150,
    render(_, tyre) {
      return <ConnectedEditableCell model={"tyres"} rowId={tyre.id} field={'size'}
                                    initialValue={tyre.size?.toString() || ''}/>
    }
  },
]
