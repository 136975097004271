import React from "react";

function About() {
    // @ts-ignore
    return (
        <div className="About">
            <h1>About</h1>

        </div>
    )
}

export default About;
