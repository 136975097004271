import React from "react";
import moment from "moment";
import {DatePicker, Menu, Switch} from "antd";
import {
    CalendarOutlined,
    CarOutlined,
    DeleteOutlined,
    FileExcelOutlined,
    PushpinOutlined,
    ScheduleOutlined,
    SearchOutlined
} from "@ant-design/icons";

import {TyreFilterState} from "./types";
import {seasonLabels, statusLabels} from "../common/data";
import {useLocations} from "../GlobalContextRoot";
import {CheckboxOptionList} from "../inputs/CheckboxOptionList";
import {toggle} from "../common/toggleInArray";
import {getExportUrl} from "../common/export";
import {DEFAULT_SEARCH_IN, DEFAULT_TYRE_FILTERS, DEFAULT_TYRE_STATUS, toQueryString} from "./tyreFiltering";
import {FilterFrameHeader} from "../FilterFrameHeader";
import {FilterStateMultiSearch} from "../MultiSearch";
import {SpecialSearchAlert} from "../SpecialSearchAlert";


/**
 * Determine whether the given filter state is "empty" (no effective filters are defined)
 * @param value
 */
function isFiltersEmpty(value: TyreFilterState): boolean {
    return !(value.searchQuery ||
        value.intakeDateAfter ||
        value.intakeDateBefore ||
        (JSON.stringify(value.selectedStatus) !== JSON.stringify(DEFAULT_TYRE_STATUS)) ||
        (value.searchIn?.length !== DEFAULT_SEARCH_IN.length) || //Double denial. if length = x, see that as if the filter is empty
        value.selectedLocations?.length ||
        value.selectedSeasons?.length ||
        (value.trashed && value.trashed !== 'false')
    );
}

export const TyreFilters: React.FC<{
    value: TyreFilterState;
    onChange: (value: TyreFilterState) => void;
}> = ({value, onChange}) => {
    
    const availableLocations = useLocations();
    
    // TODO extract these out to generic functions
    const changeValue  = (key: keyof TyreFilterState) => (valueAtKey: any) => onChange({...value, [key]: valueAtKey});
    const changeToggle = (key: keyof TyreFilterState) => (item: string, present: boolean) => changeValue(key)(toggle(value[key] || [] as any, item, present));
    
    let showDeleted = !!value.trashed;
    
    const exportCurrent = getExportUrl('tyre', 'xlsx', toQueryString(value) + '&export');
    const exportAll     = getExportUrl('tyre', 'xlsx', '?export');
    
    const hasFilters = !isFiltersEmpty(value);
    
    return <Menu
        mode="inline"
        defaultOpenKeys={['locations', 'seasons', 'search', 'filterDates']}
        style={{height: '100%'}}
    >
        <FilterFrameHeader onReset={
            hasFilters ? () => onChange(DEFAULT_TYRE_FILTERS) : undefined
        }>
            Banden
        </FilterFrameHeader>
        
        <Menu.SubMenu key="export" icon={<FileExcelOutlined/>} title="Exporteren" style={{marginTop: 10}}>
            <div style={{padding: '5px 23px'}}>
                {hasFilters ?
                    <a className={'normal-link'} style={{marginBottom: 10}} rel="noreferrer" href={exportCurrent}
                       target={'_blank'}>
                        Met huidige filters (xlsx)</a> : null}
                <a className={'normal-link'} href={exportAll} rel="noreferrer" target={'_blank'}>Alle banden (xlsx)</a>
            </div>
        </Menu.SubMenu>
        
        <Menu.SubMenu key="locations" icon={<PushpinOutlined/>} title="Vestigingen" style={{marginTop: 10}}>
            <CheckboxOptionList
                labels={availableLocations}
                selected={value.selectedLocations || []}
                setSelected={changeToggle('selectedLocations')}
            />
        </Menu.SubMenu>
        
        <Menu.SubMenu key="seasons" icon={<CalendarOutlined/>} title="Types (seizoen)">
            <CheckboxOptionList
                labels={seasonLabels}
                selected={value.selectedSeasons || []}
                setSelected={changeToggle('selectedSeasons')}
            />
        </Menu.SubMenu>
        
        <Menu.SubMenu key="status" icon={<CarOutlined/>} title="Status">
            <CheckboxOptionList
                labels={statusLabels}
                selected={value.selectedStatus || []}
                setSelected={changeToggle('selectedStatus')}
            />
        </Menu.SubMenu>
        
        <Menu.SubMenu style={{marginTop: 10}} key="search" icon={<SearchOutlined/>} title="Zoeken">
            <FilterStateMultiSearch
                value={value}
                onChange={onChange}
                labels={{
                    name:    'Op klantnaam',
                    license: 'Op kenteken',
                    comment: 'In aantekeningen',
                    row:     'Op rij',
                    pile:    'Op stapel',
                }}
                overrideOptionList={searchIn => {
                    if (searchIn[0] === 'name_strict') return <SpecialSearchAlert
                        fieldLabel={'naam'}
                        reset={() => onChange({...value, searchIn: DEFAULT_SEARCH_IN})}
                    />;
                }}
            />
        </Menu.SubMenu>
        <Menu.SubMenu style={{marginTop: 10}} key="filterDates" title="Innamedatum" icon={<ScheduleOutlined/>}>
            <div style={{margin: '0 23px 6px'}}>
                <DatePicker
                    placeholder={"Ingenomen na"}
                    style={{width: '100%'}}
                    value={value.intakeDateAfter ? moment(value.intakeDateAfter) : undefined}
                    format="DD-MM-YYYY"
                    onChange={changeValue('intakeDateAfter')}
                />
            </div>
            <div style={{margin: '10px 23px 16px'}}>
                <DatePicker
                    placeholder={"Ingenomen voor"}
                    style={{width: '100%'}}
                    value={value.intakeDateBefore ? moment(value.intakeDateBefore) : undefined}
                    format="DD-MM-YYYY"
                    onChange={changeValue('intakeDateBefore')}
                />
            </div>
        </Menu.SubMenu>
        <Menu.SubMenu style={{margin: '10px 0'}} key="filterDeleted" title="Verwijderd" icon={<DeleteOutlined/>}>
            <div style={{display: "flex"}}>
                <div style={{margin: '0 23px 16px'}}>
                    {/* // @ts-ignore*/}
                    <Switch checked={showDeleted} size="small" onChange={changeValue("trashed")}/>
                </div>
                <div style={{margin: '10px 23px 16px'}}>
                    Toon verwijderd
                </div>
            </div>
        </Menu.SubMenu>
    
    </Menu>;
};
