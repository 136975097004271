import React from "react";
import {Button} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";

export const AddButton: React.FC<{
    onClick?: () => void,
}> = ({onClick}) => {
    return <Button style={{marginLeft: 'auto', display: 'block'}} onClick={onClick} type="primary">
        <PlusCircleOutlined/>
    </Button>;
};
