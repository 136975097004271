import React, {useState} from "react";
import {Comment} from "antd";
import {apiEndpoint, getDefaultHeaders} from "../common/data";
import useFetch from "use-http";
import {EditableTextArea} from "../TableComponents/EditableTextArea";
import {ApiComment} from "../Tyre/types";
import {ConnectedDeleteButton} from "../TableComponents/ConnectedDeleteButton";

export const CommentCell: React.FC<{
    comment: ApiComment
    reload: () => void
}> = ({comment, reload}) => {
    
    const [initVal, setInitVal] = useState(comment.comment);
    
    const BASE_URL = `${apiEndpoint}/comments/${comment.id}`
    
    const options: any = {
        cachePolicy: 'no-cache',
        headers:     getDefaultHeaders()
    }
    
    const {loading, put} = useFetch(BASE_URL, options);
    
    async function saveToDB(value: string) {
        if (value !== initVal) {
            await put({"comment": value})
            setInitVal(value);
        }
    }
    
    const date = comment.updated_at ?
        new Date(comment.updated_at).toLocaleDateString() + " " + new Date(comment.updated_at).toLocaleTimeString() :
        new Date(comment.created_at).toLocaleDateString() + " " + new Date(comment.created_at).toLocaleTimeString()
    
    return <Comment style={{margin: 2}} key={comment.id} className={"comment-box"} datetime={date}
                    author={comment.user?.email}
                    content={<div>
                        <div style={{marginBottom: 10}}>
                            <EditableTextArea loading={loading}
                                              initialValue={comment.comment}
                                              onChange={(value: string) => saveToDB(value)}/>
                        </div>
                        <div style={{marginBottom: -20}}>
                            <ConnectedDeleteButton model={'comments'} rowId={comment.id}
                                                   reload={reload}/>
                        </div>
                    </div>}
    />
    
};