import React, {CSSProperties} from "react";
import {useFetch} from "use-http";

import addressCard from './address-card.svg';
import car from './car.svg';
import logout from './logout.svg';
import tyreStack from './tyre-stack.svg';
import userShield from './user-shield-solid.svg';
import user from './user-solid.svg';
import building from './building-solid.svg'

import "./CustomIcon.scss";

const customIconUrls = {
    'address-card': addressCard,
    'car':          car,
    'logout':       logout,
    'tyre-stack':   tyreStack,
    'user':         logout,
    'admin':        userShield,
    'users':        user,
    'business':     building,
};

export const CustomIcon: React.FC<{
    icon: keyof typeof customIconUrls,
    style?: CSSProperties,
}> = ({icon, style}) => {
    
    // When we import svgs (see top of this file), the imported object is the path/URL to the asset rather
    // than the asset body. The way we're dealing with this now is making a separate (fetch) request and
    // inserting the response as raw HTML into the element below - which isn't ideal - TODO improve
    
    const {response} = useFetch(customIconUrls[icon], []);
    
    return <span
        className={'custom-icon'}
        style={style}
        dangerouslySetInnerHTML={{__html: response?.data || ''}}
    />
};
