import React from "react";
import {ColumnsType} from "antd/es/table";
import {ApiUser} from "./types";
import {hasEditableFields} from "../common/headerCell";
import {AddButton} from "../AddButton";
import {ConnectedRestoreButton} from "../TableComponents/ConnectedRestoreButton";
import {ConnectedDeleteButton} from "../TableComponents/ConnectedDeleteButton";
import {ConnCompanyDropDownCell} from "../Company/ConnCompanyDropDownCell";
import {CheckSquareOutlined, CloseSquareTwoTone} from "@ant-design/icons";
import {UserProfile} from "../hooks/useUserProfile";
import {RolesCell} from "./RolesCell";
import {Tooltip} from "antd";

export const UserGridColumns = (
    showAddUserModal: () => void,
    reload: () => void,
    userProfile: UserProfile | undefined
): ColumnsType<ApiUser> => ([
    {
        ...hasEditableFields,
        key:       "email",
        dataIndex: "email",
        title:     "E-mail",
        render(_, user) {
            return <div className={"module"} style={{marginLeft: 12}}>{user.email}</div>;
        }
    }, {
        ...hasEditableFields,
        key:       "company",
        dataIndex: "company",
        title:     "Bedrijf",
        render(_, user) {
            return user.id !== userProfile?.id ? <ConnCompanyDropDownCell
                model={"users"} initialValue={user.company.id} field={"company_id"}
                rowId={user.id}
                selectedCompanyName={user.company.name}
                onChange={(value) => undefined}
            /> : <div className={"module"} style={{marginLeft: 12}}>{user.company.name}</div>
        }
    }, {
        key:       "roles",
        dataIndex: "roles",
        title:     "Rollen",
        render(_, user) {
            return user.id !== userProfile?.id ? <RolesCell
                defaultValues={user.roles.map((role: any) => role.name)}
                rowId={user.id}
                userProfile={userProfile}
            /> : <Tooltip title={user.roles.map((role: any) => role.name + ' ')}
                          className={"module line-clamp-1"}>{user.roles.map((role: any) => role.name + ' ')}</Tooltip>
        }
    }, {
        ...hasEditableFields,
        key:       "email_verified_at",
        dataIndex: "email_verified_at",
        title:     "E-mail geverifieerd",
        render(_, user) {
            return <>
                {
                    user.email_verified_at ?
                        <CheckSquareOutlined className={"module"}/> :
                        <CloseSquareTwoTone twoToneColor={"red"} className={"module"}/>
                }
            </>
        }
    }, {
        key:       'add',
        dataIndex: 'add',
        title:     <AddButton onClick={showAddUserModal}/>,
        width:     62,
        fixed:     "right",
        render(_, user) {
            return user.id === userProfile?.id ? undefined :
                user.deleted_at ?
                    <ConnectedRestoreButton model='users' rowId={user.id} reload={reload}/> :
                    <ConnectedDeleteButton model='users' rowId={user.id} reload={reload}/>
            
        }
    }
])