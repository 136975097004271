import React, {ReactNode} from "react";
import {Layout} from "antd";

import {Header} from "./Header";
import {GlobalContextRoot} from "./GlobalContextRoot";
import {getAccessToken} from "./common/data";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {RedirectToLogin} from "./RedirectToLogin";

/**
 * Default page layout including navigation / header
 */
const PageLayoutContainer: React.FC<{children: ReactNode}> = ({children}) => {

  const {xs, lg} = useBreakpoint();

  const horizontalPadding = lg ? 50 : (xs ? 0 : 32)

  return <div className="App">
    <Header/>
    <Layout.Content style={{padding: `10px ${horizontalPadding}px 50px`}}>
      <Layout className="site-layout-background" style={{padding: '24px 0'}}>
        {children}
      </Layout>
    </Layout.Content>
  </div>;
}

/**
 * Redirect to "/login" if the user is not logged in. Otherwise, just render the children.
 */
export const AuthGuard: React.FC<{ children: ReactNode }> = ({children}) => {
  const loggedIn = !!getAccessToken();
  if (!loggedIn) {
    return <RedirectToLogin/>;
  }
  return <>{children}</>;
}

/**
 * Wraps the page in a default layout, provides global context, and redirects user to "/login" if they
 * are not currently logged in.
 */
export const PageRoot: React.FC<{ children: ReactNode }> = ({children}) => {
  return <AuthGuard>
    <GlobalContextRoot>
      <PageLayoutContainer>
        {children}
      </PageLayoutContainer>
    </GlobalContextRoot>
  </AuthGuard>;
};
