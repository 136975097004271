import React from "react";
import {Form, Input, notification} from "antd";
import {useFetch} from "use-http";
import {apiEndpoint, getDefaultHeaders} from "../common/data";
import {ModalFormFooter} from "../ModalFormFooter";
import {displayError} from "../displayError";

export const CustomerAddForm: React.FC<{
    setCustomerModalVisibility: (value: boolean) => void,
    reload: () => void,
}> = ({setCustomerModalVisibility, reload}) => {

    const [form] = Form.useForm();

    const BASE_URL = `${apiEndpoint}/customers`;

    const {loading, post, response} = useFetch(BASE_URL, {
        headers: getDefaultHeaders(),
    })

    async function saveToDB() {
        await post(await form.getFieldsValue());
    }

    //TODO: IF-ELSE en try-catch voelt samen niet helemaal lekker. navragen
    // ook duplicate code met de andere forms
    async function validateAndSave(closeModal: boolean) {
        try {
            await form.validateFields();
            await saveToDB();
            form.resetFields();
            if (response.ok) {
                notification.success({
                    message: "Object opgeslagen",
                });
            } else {
                notification.error({
                    message: "An error occurred",
                    description: response.statusText
                })
            }
            /**
             * closemodal = true => visibility = false => opposite
             */
            setCustomerModalVisibility(!closeModal)
            reload()
        } catch (e: any) {
            displayError(e);
        }
    }


    return <div>
        <Form
            form={form}
            id="customerForm"
            labelCol={{span: 6}}
            wrapperCol={{span: 16}}
            layout="horizontal"
        >
            <Form.Item label="(Bedrijfs-) Naam" name="name"
                       rules={[{required: true, message: 'Dit veld is verplicht.'}]}>
                <Input/>
            </Form.Item>

            <Form.Item label="Straat" name="street" rules={[{required: true, message: 'Dit veld is verplicht.'}]}>
                <Input/>
            </Form.Item>
            <Form.Item label="Huisnummer" name="housenumber"
                       rules={[{required: true, message: 'Dit veld is verplicht.'}]}>
                <Input/>
            </Form.Item>
            <Form.Item label="Postcode" name="postalcode" rules={[{required: true, message: 'Dit veld is verplicht.'}]}>
                <Input/>
            </Form.Item>
            <Form.Item label="Stad" name="city" rules={[{required: true, message: 'Dit veld is verplicht.'}]}>
                <Input/>
            </Form.Item>

            <Form.Item label="Telefoonnummer" name="phone_number">
                <Input/>
            </Form.Item>
            <Form.Item label="Contactpersoon" name="contact_person">
                <Input/>
            </Form.Item>
            <Form.Item label="Email" name="email">
                <Input/>
            </Form.Item>
            <ModalFormFooter
                onCancel={() => setCustomerModalVisibility(false)}
                onSave={addAnother => validateAndSave(!addAnother)}
                loading={loading}
            />
        </Form>
    </div>

};
