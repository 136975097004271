import buildUrl from "build-url";

import {UserFilterState} from "./types";
import {getFilterQuery} from "../gridFiltering";

export const USER_FILTER_SESSION_KEY = 'USER_FILTERS';
export const DEFAULT_SEARCH_IN       = ['email', 'company']

export function toQueryString(filter: UserFilterState, sort?: string): string {
    return buildUrl('', {
        queryParams: {
            ...(sort ? {sort} : {}),
            'filter[special]': getFilterQuery(filter) || '',
            'filter[trashed]': filter.trashed ? "only" : "false" || '',
        },
    });
}