import buildUrl from "build-url";

import {CustomerFilterState} from "./types";
import {getFilterQuery} from "../gridFiltering";
import {CUSTOMER_TYRES_SLIDER_RANGE} from "./constants";

export const CUSTOMER_FILTER_SESSION_KEY = 'CUSTOMER_FILTERS';
export const DEFAULT_SEARCH_IN           = ['name', 'postalcode', 'phone_number'];

export function toQueryString(filter: CustomerFilterState, sort?: string): string {

    const minTyres: string = (typeof filter.minTyres !== 'undefined' && filter.minTyres !== 0) ? String(filter.minTyres) : ''
    const maxTyres: string = (typeof filter.maxTyres !== 'undefined' && filter.maxTyres < CUSTOMER_TYRES_SLIDER_RANGE) ? String(filter.maxTyres) : '';

    return buildUrl('', {
        queryParams: {
            ...(sort ? {sort} : {}),
            'filter[special]': getFilterQuery(filter),
            'append': 'tyre_count',
            'filter[trashed]': filter.trashed ? "only" : "false" || '',
            minTyres,
            maxTyres,
        },
    });
}
