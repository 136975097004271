/**
 * Base type for filtering interfaces that allow a search query
 * to be matches across one or more fields dynamically
 */
export type MultiSearchFilterState = {
    searchIn?: string[];
    searchQuery?: string;
};

/**
 * Get the `filter[special]` url parameter value representing the given
 * "multi filter" state
 * @param filter
 */
export function getFilterQuery(filter: MultiSearchFilterState): string {
    if (filter.searchQuery) {
        return ((filter.searchIn || [])).join(';') + ':' + filter.searchQuery;
    } else {
        return '';
    }
}
