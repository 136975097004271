import buildUrl from "build-url";

import {CompanyFilterState} from "./types";
import {getFilterQuery} from "../gridFiltering";

export const COMPANY_FILTER_SESSION_KEY = 'COMPANY_FILTERS';
export const DEFAULT_SEARCH_IN          = ['name', 'kvk']

export function toQueryString(filter: CompanyFilterState, sort?: string): string {
    return buildUrl('', {
        queryParams: {
            ...(sort ? {sort} : {}),
            'filter[special]': getFilterQuery(filter) || '',
            'filter[trashed]': filter.trashed ? "only" : "false" || '',
            'append':          'user_count',
        },
    });
}