import React, {useState} from "react";
import {apiModels} from "../common/types";
import {Switch} from "antd";
import {CarOutlined, InboxOutlined} from "@ant-design/icons";
import useFetch from "use-http";
import {apiEndpoint, getDefaultHeaders} from "../common/data";

export const ConnectedSwitchButton: React.FC<{
  model: apiModels,
  field: string,
  rowId: number
  initialValue: string,
  reload: () => void,
  onChange?: (value: string) => void,
}> = ({model, field, rowId, initialValue, reload, onChange}) => {

  const [initVal, setInitVal] = useState(initialValue);

  const BASE_URL = `${apiEndpoint}/${model}/${rowId}`

  const options: any = {
    cachePolicy: 'no-cache',
    headers:     getDefaultHeaders(),
  };

  const {loading, put} = useFetch(BASE_URL, options);

  const save = async (checked: boolean) => {
    let value = checked ? 'storage' : 'car';
    if (rowId !== 0) {
      await put({[field]: value});
      setInitVal(value)
      reload();
    } else {
      setInitVal(value)
      onChange?.(value);
    }
  }

  return <>
    <span style={{marginRight: 5}}>auto</span>
    <Switch checked={initVal === 'storage'}
            loading={loading}
            onChange={(checked) => save(checked)}
            unCheckedChildren={<CarOutlined/>}
            checkedChildren={<InboxOutlined/>}

    />
    <span style={{marginLeft: 5}}>opslag</span>
  </>
};
