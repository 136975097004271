import {ColumnsType} from "antd/es/table";
import {ApiTyre} from "./types";
import {ConnectedSwitchButton} from "../TableComponents/ConnectedSwitchButton";
import React from "react";
import {tyreDetailsColumns} from "./TyreDeailsColums";
import {ConnCommentCell} from "../Comment/ConnCommentCell";
import {hasEditableFields} from "../common/headerCell";
import {ConnectedDatePickerCell} from "../TableComponents/ConnectedDatePickerCell";
import {PdfCell} from "../Pdf/PdfCell";


export const columns = (
  reload: () => void
): ColumnsType<ApiTyre> => [
  {
    key:       'label',
    dataIndex: 'label',
    width:     50,
    align:     "center",
    fixed:     'left',
    render(_, tyre) {
      return <PdfCell type="label" tyreId={tyre.id}/>
    },
  },
  ...tyreDetailsColumns(false, reload),
  {
    ...hasEditableFields,
    key:       'last_intake_at',
    dataIndex: 'last_intake_at',
    title:     'Inname',
    width:     180,
    render(_, tyre) {
      return <ConnectedDatePickerCell model={"tyres"} rowId={tyre.id} field={'last_intake_at'}
                                      initialValue={tyre.last_intake_at}
                                      onChanged={(value) => null}/>
    }
  },
  {
    key:       'comment',
    dataIndex: 'comment',
    title:     'Aantekening',
    width:     180,
    render(_, tyre) {
      let text = tyre.most_recent_comment ? tyre.most_recent_comment.comment.toString() : undefined;
      return (
        <ConnCommentCell comment={text} tyreId={tyre.id} reload={reload}/>
      );
    },
  },
  {
    key:       'status',
    dataIndex: 'status',
    title:     'Status',
    align:     'center',
    width:     150,
    fixed:     "right",
    render:    (_, tyre) => <ConnectedSwitchButton
      model={"tyres"}
      rowId={tyre.id}
      field={"status"}
      initialValue={tyre.status}
      reload={reload}
    />

  }
];
