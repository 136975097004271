import React, {useEffect} from "react";
import {Redirect, useLocation} from "react-router-dom";
import {rememberPath} from "./common/data";

export const RedirectToLogin: React.FC<{
    pathAfterLogin?: string | undefined
}> = ({pathAfterLogin}) => {
    
    const location = useLocation();
    
    useEffect(() => {
        // Store "desired location" so we can redirect back there after logging in
        pathAfterLogin ? rememberPath(pathAfterLogin) : rememberPath(location.pathname);
        localStorage.removeItem('access_token')
        localStorage.removeItem('access_token_expires');
    }, []); /* eslint-disable-line react-hooks/exhaustive-deps */
    
    return <Redirect to={"/login"}/>;
};
