import React from "react";
import {useFetch} from "use-http";
import {apiEndpoint, getDefaultFetchOptions} from "../common/data";
import {CustomMultiSelect} from "../TableComponents/CustomMultiSelect";
import {UserProfile} from "../hooks/useUserProfile";

/*
 create or update the roles.
 if no rowId available, give 0
 if the rowId is 0, it will be an create, otherwise there will be a update
 */
export const RolesMultiSelect: React.FC<{
    defaultValues: string[],
    setEditing: (value: boolean) => void
    onChange: (value: any) => void
    rowId: number
    editing: boolean
    userProfile?: UserProfile | undefined
}> = ({defaultValues, onChange, rowId, editing, setEditing, userProfile}) => {
    
    const BASE_URL = `${apiEndpoint}/roles`;
    const options  = getDefaultFetchOptions();
    
    const {loading, response, data = []} = useFetch(BASE_URL, options, [BASE_URL]);
    
    const maxCount = rowId ? 1 : 99
    
    //For updating an existing user
    const UPDATE_URL = `${BASE_URL}/${rowId}` // roles/1
    
    const {put} = useFetch(UPDATE_URL, options);
    
    
    async function safeOrUpdate(value: any) {
        let values = value.concat(hiddenValues)
        
        if (rowId === 0) {
            onChange(values)
        } else {
            await (put({'roles': values}))
        }
    }
    
    if (!loading && !response.ok) return <p>An error occurred.</p>;
    
    //TODO: What if there are more values to be hidden in the future?
    // Normally all other users will not be a super admin, and also not have the rights. For now this will suffice
    
    let hiddenValues: string[] = []
    if (rowId) {
        hiddenValues = userProfile?.rolesCustom.includes('super_admin') ? [] : ['super_admin']
    }
    //If the user already exists (rowId is set) and the user is not a super_admin, don't show all roles
    let defaultValuesToShow: string[] = defaultValues.filter(value => !hiddenValues.includes(value))
    let allRoles: string[]            = []
    //only show the roles that the user has the right to see.
    if (data.data)
        allRoles = data.data.map((data: any) => !hiddenValues.includes(data.name) ? data.name : null);
    
    
    return <CustomMultiSelect
        editing={editing}
        setEditing={(value: boolean) => setEditing(value)}
        values={allRoles}
        onChange={(value) => safeOrUpdate(value)}
        defaultValues={defaultValuesToShow}
        maxCount={maxCount}
    />
    
};