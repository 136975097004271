import React from "react";
import {Layout} from "antd";

import {TyreFilters} from "./TyreFilters";
import TyreGrid from "./TyreGrid";
import {FilterFrame} from "../FilterFrame";
import {useSessionState} from "../hooks/useSessionState";
import {TyreFilterState} from "./types";
import {DEFAULT_TYRE_FILTERS, TYRE_FILTER_SESSION_KEY} from "./tyreFiltering";
import {useParams} from "react-router-dom";
import {TyreUpdateModal} from "./TyreUpdateModal";

const Tyre: React.FC = () => {

  const [filters, setFilters] = useSessionState<TyreFilterState>(TYRE_FILTER_SESSION_KEY, DEFAULT_TYRE_FILTERS);

  let params = useParams<{ id?: string }>();


  return <Layout style={{display: "flex"}}>
    {params?.id ? <TyreUpdateModal visibility={true}/> : null}
    <FilterFrame>
      <TyreFilters value={filters} onChange={setFilters}/>
    </FilterFrame>
    <Layout>
      <TyreGrid filters={filters}/>
    </Layout>
  </Layout>;
};

export default Tyre;
