import React from "react";
import {Checkbox} from "antd";

export const CheckboxOption: React.FC<{
    selected: boolean,
    onSelected: (selected: boolean) => void,
    label: string,
}> = ({selected, onSelected, label}) => {
    return <div style={{padding: '10px 20px 10px 23px'}}>
        <Checkbox
            checked={selected}
            onChange={e => onSelected(e.target.checked)}
        >{label}</Checkbox>
    </div>;
}
