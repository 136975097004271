import {CachePolicies, IncomingOptions} from "use-http";


/**
 * Derive an API endpoint from the current hostname (for "hybrid" instances
 * where the back- and frontend are served from the same host)
 */
const hybridEndpoint = (): string => {
    const portPart                         = window.location.port ? (':' + window.location.port) : '';
    const endpoint                         = window.location.protocol + "//" + window.location.hostname + portPart + '/v3';
    (window as any).__endpoint_diagnostics = {
        endpoint,
        protocol: window.location.protocol,
    };
    return endpoint;
};

export const apiEndpoint = process?.env?.REACT_APP_API_ENDPOINT || hybridEndpoint();

/**
 * Retrieve a valid access token from local storage. If no token is available,
 * or the token has expired, returns `null`.
 */
export const getAccessToken = (): string | null => {
    const token = localStorage.getItem('access_token');
    if (!token) return null;
    const expires = localStorage.getItem('access_token_expires');
    if (Date.now() >= Number(expires)) {
        // Token has expired - user needs to log in again
        console.warn('Token has expired - user needs to log in again.');
        // TODO generic redirect to '/login' - this is currently naively enforced at `src/GlobalContextRoot.tsx:42`
        return null;
    }
    return token;
};

export const storeAccessToken = (token: string, ttlSeconds: number): void => {
    localStorage.setItem('access_token', token);
    localStorage.setItem('access_token_expires', (Date.now() + ttlSeconds * 1000).toString());
};

export const forgetAccessToken = (): void => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expires');
};

export const getDefaultHeaders = () => ({
    Authorization: `Bearer ${getAccessToken()}`,
});

export const getDefaultFetchOptions = (): IncomingOptions => ({
    cachePolicy: CachePolicies.NO_CACHE,
    headers:     getDefaultHeaders(),
    method:      "GET"
});

export type SeasonLiteral = 'summer' | 'winter';

export const availableSeasons: SeasonLiteral[] = ['summer', 'winter'];

export const seasonLabels: Record<SeasonLiteral, string> = {
    summer: 'Zomer',
    winter: 'Winter',
};

export type StatusLiteral = 'storage' | 'car';

export const statusLabels: Record<StatusLiteral, string> = {
    storage: 'Opslag',
    car:     'Auto',
}


/**
 * Store the "original" path that the user was trying to navigate to before being redirected to the login screen.
 */
export const rememberPath = (path: string): void => {
    if (!/^\/?(login)?$/.test(path)) {
        sessionStorage.setItem('navigate_after_login', path);
    } else {
        sessionStorage.removeItem('navigate_after_login');
    }
}

/**
 * Determine the path that should be redirected to after logging in
 */
export const pathAfterLogin = (def: string = '/tyre'): string => {
    return sessionStorage.getItem('navigate_after_login') ?? def;
}
