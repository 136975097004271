import React, {useEffect, useState} from "react";
import {useFetch} from "use-http";
import {apiEndpoint, getDefaultHeaders} from "../common/data";
import {ValuedDropDown} from "../TableComponents/ValuedDropDown";

export const ConnLicensePlateDropDownCell: React.FC<{
    selectedLicensePlate?: string,
    initialValue: number | undefined
    onChange: (value: number) => void,
}> = ({selectedLicensePlate, initialValue, onChange}) => {
    
    /**
     * if Initvalue is undefined, it means that there will be a new one,
     * the licenseplate is created with a whole new tyre.
     * if there already is a tyre, but without a licenseplate the value will be -1, so not undefined
     */
    const [editing, setEditing] = useState(false);
    
    const options: any = {
        cachePolicy: 'no-cache',
        headers:     getDefaultHeaders()
    }
    
    const {loading, get, data} = useFetch(apiEndpoint + `/cars/licenseplates`, options, [initialValue]);
    
    useEffect(() => {
        if (editing) {
            get();
        }
    }, [editing]); /* eslint-disable-line react-hooks/exhaustive-deps */
    
    const availableLicensePlates = data?.data || [];
    
    availableLicensePlates.map((car: any) => car.name = car.license_plate)
    
    return <ValuedDropDown
        editing={initialValue ? editing : true}
        setEditing={setEditing}
        loading={loading}
        availableValues={availableLicensePlates}
        initialValue={initialValue}
        selectedValue={selectedLicensePlate}
        onChange={value => onChange(value)}
        isNullable={false}
    />
};
