import useFetch from "use-http";
import React, {createContext, ReactNode, useContext} from "react";

import {apiEndpoint, getDefaultHeaders} from "./common/data";
import {SplashScreen} from "./SplashScreen";
import {Spin} from "antd";
import {RedirectToLogin} from "./RedirectToLogin";

/**
 * Holds data relevant to various components in the app.
 */
export const GlobalContext = createContext<{
  availableLocations: string[] | null;
}>({availableLocations: null});

/**
 * Return available locations from the global context
 */
export function useLocations(): string[] {
  const locations = useContext(GlobalContext).availableLocations;
  if (!locations) throw new Error('Components using the "useLocations" hook should have the context available to them.');
  return locations;
}

const LoadingScreen: React.FC = () => {
  return <SplashScreen>
    <div style={{
      display:    'flex',
      alignItems: 'center',
    }}>
      <Spin style={{marginTop: 5, marginRight: 15}}/> Laden...
    </div>
  </SplashScreen>;
}

export const GlobalContextRoot: React.FC<{ children: ReactNode }> = ({children}) => {

  let {loading, data, error} = useFetch(apiEndpoint + '/tyres/location', {headers: getDefaultHeaders()}, []);

  // TODO this should be implemented (somehow) for all API requests
  const unauthorized = error && /unauthorized/i.test(error.message);
  if (unauthorized) return <RedirectToLogin/>

  const availableLocations = data?.data?.map(({location}: { location: string }) => location) || null;

  return (loading || error) ? <LoadingScreen/> : <GlobalContext.Provider value={{availableLocations}}>
    {children}
  </GlobalContext.Provider>;
};
