import React from "react";
import {Modal} from "antd";
import {CustomerAddForm} from "./CustomerAddForm";

export const CustomerModal: React.FC<{
    customerModalVisible: boolean,
    setCustomerModalVisibility: (value: boolean) => void,
    reload: () => void,
}> = ({customerModalVisible, setCustomerModalVisibility, reload}) => {

    return (
        <Modal
            visible={customerModalVisible}
            style={{top: 30}}
            title="Nieuwe klant"
            onCancel={() => setCustomerModalVisibility(false)}
            footer={false}
        >
            <CustomerAddForm setCustomerModalVisibility={(value: boolean) => setCustomerModalVisibility(value)}
                             reload={reload}/>
        </Modal>

    );
}