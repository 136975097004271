import React from "react";
import {Form, notification} from "antd";
import {useFetch} from "use-http";
import {apiEndpoint, getDefaultHeaders} from "../common/data";
import TextArea from "antd/es/input/TextArea";
import {ModalFormFooter} from "../ModalFormFooter";
import {displayError} from "../displayError";

export const CommentInputForm: React.FC<{
    setModalVisibility: (value: boolean) => void,
    row: number
    reload: ()=>void,
}> = ({setModalVisibility, row,reload}) => {

    const BASE_URL = `${apiEndpoint}/comments`
    const [form] = Form.useForm();
    const options: any = {
        cachePolicy: 'no-cache',
        headers: getDefaultHeaders()
    }
    const {loading, post} = useFetch(BASE_URL, options);

    async function saveToDB(values: any) {
        await post({
            "tyre_id": row,
            "comment": values['comment']
        })
    }

    async function validateAndSave(closeModal: boolean) {
        try {
            const values = await form.validateFields();
            await saveToDB(values);
            form.resetFields();
            notification.success({
                message: "Object opgeslagen",
            });
            /**
             * closemodal = true => visibility = false => opposite
             */
            reload()
            setModalVisibility(!closeModal)
        } catch (e: any) {
            displayError(e);
        }
    }

    return <Form
        form={form}
    >
        <Form.Item rules={[{required: true, message: "Dit veld is verplicht."}]} label="Aantekening" name="comment"
                   key={row}>
            <TextArea/>
        </Form.Item>

        <ModalFormFooter onCancel={() => setModalVisibility(false)}
                         onSave={addAnother => validateAndSave(!addAnother)}
                         loading={loading}
                         saveOnly={true}
        />

    </Form>

};
