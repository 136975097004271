import {useMemo, useState} from "react";

export type ValueUpdater<T> = ((value: T) => void);

/**
 * Same as `useState` hook, but load initial value from the sessionStorage and
 * set it to the sessionStorage when setState is called
 * @param key
 * @param defaultValue
 */
export function useSessionState<T>(key: string, defaultValue: T): [T, ValueUpdater<T>] {

    const valueFromSession = useMemo<T | null>(() => {
        const item = sessionStorage.getItem(key);
        return item !== null ? JSON.parse(item) : null;
    }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

    const [state, setState] = useState(valueFromSession ?? defaultValue);

    return [state, (newValue: T) => {
        sessionStorage.setItem(key, JSON.stringify(newValue));
        setState(newValue);
    }];
}
