import React, {useState} from "react";
import {MailOutlined} from "@ant-design/icons";
import {ApiCustomer} from "../Customer/types";
import {Tooltip} from "antd";
import {ConnectedEditableCell} from "./ConnectedEditableCell";

export const MailCell: React.FC<{
    customer: ApiCustomer
}> = ({customer}) => {

    const sendMail = () => {
        window.open(`mailto:${mail}`)
    }

    const [mail, setMail] = useState<string>(customer.email)

    return (
        <div style={{display: "flex"}}>
            <Tooltip title={customer.email}
                     className={"module line-clamp-1"}>
                <ConnectedEditableCell model={"customers"} rowId={customer.id} field={'email'}
                                       initialValue={(customer.email ? customer.email.toString() : "")}
                                       onChange={(newMail) => {setMail(newMail)}}
                />
            </Tooltip>
            {customer.email ? <MailOutlined style={{color: "#1890ff", marginTop: 10}} onClick={sendMail}/> : null}
        </div>
    )
};