import React, {ReactNode} from "react";
import {Button} from "antd";

export const FilterFrameHeader: React.FC<{
  onReset?: () => void;
  children: ReactNode
}> = ({onReset, children}) => {
  return <div style={{
    display:    'flex',
    alignItems: 'center',
    padding:    '23px 23px 15px',
  }}>
    <h3 style={{
      margin:     0,
      lineHeight: '14px',
    }}>{children}</h3>
    {onReset ? <Button
      type={'link'}
      onClick={onReset}
      style={{
        margin:       '-10px -7px -10px auto',
        paddingRight: 0,
      }}
    >reset filters</Button> : null}
  </div>;
};
