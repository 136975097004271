import React, {useState} from "react";
import {PhoneOutlined} from "@ant-design/icons";
import {ApiCustomer} from "../Customer/types";
import {ConnectedEditableCell} from "./ConnectedEditableCell";

export const CallToCell: React.FC<{
    customer: ApiCustomer
    phoneNumber: string
}> = ({customer, phoneNumber}) => {

    const [number, setNumber] = useState<string>(customer.phone_number)

    function getCorrectNumber(number: string) {
        return number.split("/")[0]
    }

    const callTo = () => {
        if (phoneNumber.length >= 10) {
            var phone = getCorrectNumber(number);
            window.open(`callto:${phone}`)
        }
    }

    return <div style={{display: "flex"}}>
        <ConnectedEditableCell
            model={"customers"}
            rowId={customer.id}
            field={'phone_number'}
            initialValue={(number ? number.toString() : "")}
            onChange={(newNumber) => setNumber(newNumber) }
        />
        {phoneNumber ? <PhoneOutlined style={{color: "#1890ff", marginTop: 10}} onClick={callTo}/> : null}
    </div>

};