import React from "react";
import {Modal} from "antd";
import {CompanyAddForm} from "./CompanyAddForm";

export const CompanyModal: React.FC<{
    companyModalVisible: boolean,
    setCompanyModalVisible: (value: boolean) => void,
    reload: () => void,
}> = ({companyModalVisible, setCompanyModalVisible, reload}) => {
    
    return (
        <Modal
            visible={companyModalVisible}
            style={{top: 30}}
            title="Nieuw bedrijf"
            onCancel={() => setCompanyModalVisible(false)}
            footer={false}
        >
            <CompanyAddForm setModalCompanyVisibility={(value: boolean) => setCompanyModalVisible(value)}
                            reload={reload}/>
        </Modal>
    );
    
};