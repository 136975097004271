import React, {useState} from "react";
import {DatePicker} from "antd";
import moment from 'moment';
import {apiEndpoint, getDefaultHeaders} from "../common/data";
import useFetch from "use-http";
import {apiModels} from "../common/types";

export const ConnectedDatePickerCell: React.FC<{
  model: apiModels,
  rowId: number,
  field: string,
  initialValue: string
  onChanged: (value: any) => void
}> = ({model, rowId, field, initialValue, onChanged}) => {
  //https://ant.design/components/date-picker/

  const [initVal, setInitVal] = useState(initialValue);
  const BASE_URL              = `${apiEndpoint}/${model}/${rowId}`

  // const defaultValue =

  const options: any = {
    cachePolicy: 'no-cache',
    headers:     getDefaultHeaders()
  }

  const {loading, put} = useFetch(BASE_URL, options);

  async function onChange(value: any, dateFormat: any) {
    if (value !== null && value !== initialValue && rowId !== 0) {
      await put({[field]: dateFormat});
      setInitVal(value);
    }
    onChanged(dateFormat);
  }

  return <div>
    <DatePicker bordered={rowId === 0} disabled={loading} onChange={onChange}
                placeholder="n.b"
                format="DD-MM-YYYY" defaultValue={initVal ? moment(initVal) : undefined}/>
  </div>;
};
