import React from "react";
import {Badge, Collapse, Form, Input, InputNumber, notification} from "antd";
import TextArea from "antd/es/input/TextArea";

import {apiEndpoint, availableSeasons, getDefaultFetchOptions, seasonLabels} from "../common/data";
import {ModalFormFooter} from "../ModalFormFooter";
import {useLocations} from "../GlobalContextRoot";
import useFetch from "use-http";
import {ApiTyre} from "./types";
import {ConnectedDropDownCell} from "../TableComponents/ConnectedDropDownCell";
import {displayError} from "../displayError";
import {CommentHistory, useCommentCount} from "../Comment/CommentHistory";
import {ConnectedSwitchButton} from "../TableComponents/ConnectedSwitchButton";

export const TyreUpdateForm: React.FC<{
  rowId: number,
  tyre: ApiTyre,
  setTyreModalVisibility: (value: boolean) => void;
  reload: () => void
}> = ({rowId, tyre, setTyreModalVisibility, reload}) => {

  const availableLocations: string[] = useLocations()

  const commentCount = useCommentCount(tyre.id);

  const {put, loading, response} = useFetch(`${apiEndpoint}/tyres/${rowId}`, getDefaultFetchOptions());

  const {post: addComment, loading: addingComment} = useFetch(`${apiEndpoint}/comments`, getDefaultFetchOptions());

  async function saveToDB() {
    form.getFieldsValue()

    await put({
      "season":      form.getFieldValue('season'),
      "location":    form.getFieldValue('location'),
      "row":         form.getFieldValue('row'),
      "pile":        form.getFieldValue('pile'),
      "tread_depth": form.getFieldValue('tread_depth'),
      "status":      form.getFieldValue('status'),
    })

    if (form.getFieldValue('comment')) {
      await addComment({
        "tyre_id": rowId,
        "comment": form.getFieldValue('comment'),
      });
    }
  }

  async function validateAndSave(closeModal: boolean) {
    try {
      await form.validateFields();
      await saveToDB();
      if (response.ok) {
        notification.success({
          message: "Object opgeslagen",
        });
      } else {
        notification.error({
          message:     "An error occurred",
          description: response.statusText
        })
      }
      /**
       * closemodal = true => visibility = false => opposite
       */
      setTyreModalVisibility(!closeModal)
      reload();
    } catch (e: any) {
      displayError(e);
    }
  }

  const [form] = Form.useForm();

  return <div className={"compact-form"}>
    <Form
      initialValues={{
        row:         tyre.row,
        pile:        tyre.pile,
        season:      tyre.season,
        location:    tyre.location,
        tread_depth: tyre.tread_depth,
        packinglist: tyre.packinglist
      }}
      form={form}
      id="tyreForm"
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      layout="horizontal"
    >
      <Collapse defaultActiveKey={['properties', 'add-comment', 'comment-history']}>
        <Collapse.Panel key={'properties'} header={'Eigenschappen'}>
          <Form.Item label="Klant">
            <div style={{marginRight: 10, flex: 1}}>
              {tyre.customer?.name}
            </div>
          </Form.Item>
          <Form.Item key="season" label="Seizoen" name="season"
                     rules={[{required: true, message: "Dit veld is verplicht.."}]}
          >
            <ConnectedDropDownCell model={"tyres"} rowId={0} field={"season"}
                                   availableValues={availableSeasons} initialValue={tyre.season}
                                   onChange={(value) => form.setFieldsValue({'season': value})}
                                   getLabel={value => (seasonLabels as any)[value]}
            />
          </Form.Item>
          <Form.Item key="location" label="Locatie" name="location"
                     rules={[{required: true, message: "Dit veld is verplicht.."}]}
          >
            <ConnectedDropDownCell model={"tyres"} rowId={0} field={"location"}
                                   availableValues={availableLocations} initialValue={tyre.location}
                                   onChange={(value) => form.setFieldsValue({'location': value})}/>
          </Form.Item>
          <Form.Item label="Rij" name="row">
            <Input/>
          </Form.Item>
          <Form.Item label="Stapel" name="pile">
            <InputNumber style={{width: '100%'}} min={1}/>
          </Form.Item>
          <Form.Item label="Grootte" name="size"
          >
            {tyre.size}
          </Form.Item>
          <Form.Item label="Profieldiepte" name="tread_depth"
                     rules={[{required: true, message: "Dit veld is verplicht.."}]}
          >
            <Input style={{width: '100%'}}
                   placeholder={"profieldiepte"}/>
          </Form.Item>
          <Form.Item label="Pakbon">
            {tyre.packinglist}
          </Form.Item>
          <Form.Item label="Kenteken">
            <div style={{flex: 1}}>
              {tyre.car?.license_plate}
            </div>
          </Form.Item>
          <Form.Item label="Status">
            <ConnectedSwitchButton
              model={"tyres"}
              rowId={0}
              field={"status"}
              initialValue={tyre.status}
              reload={() => {
              }}
              onChange={(value) => form.setFieldsValue({'status': value})}
            />
          </Form.Item>
        </Collapse.Panel>
        <Collapse.Panel key={'add-comment'} header={'Aantekening toevoegen'}>
          <Form.Item label="Aantekening" name="comment">
            <TextArea placeholder={"Vul hier in om een aantekening toe te voegen"}/>
          </Form.Item>
        </Collapse.Panel>
        <Collapse.Panel key={'comment-history'} header={<>
          Eerdere aantekeningen
          {commentCount !== null ? <Badge style={{marginLeft: '.4rem', backgroundColor: 'rgba(0,0,0,0.6)'}}
                                          count={commentCount}/> : null}
        </>}>
          <CommentHistory tyreId={tyre.id} style={{marginTop: -10}}/>
        </Collapse.Panel>
      </Collapse>
      <ModalFormFooter
        style={{marginTop: '1rem'}}
        onCancel={() => setTyreModalVisibility(false)}
        onSave={addAnother => validateAndSave(!addAnother)}
        loading={loading || addingComment}
        saveOnly={true}
      />
    </Form>
  </div>
};
