import {ColumnsType} from "antd/es/table";
import {ApiTyre} from "./types";
import {PdfCell} from "../Pdf/PdfCell";
import {hasEditableFields} from "../common/headerCell";
import {Tooltip} from "antd";
import {statusLabels} from "../common/data";
import {ConnectedDropDownCell} from "../TableComponents/ConnectedDropDownCell";
import {ConnectedEditableCell} from "../TableComponents/ConnectedEditableCell";
import {ConnectedDatePickerCell} from "../TableComponents/ConnectedDatePickerCell";
import {ConnCommentCell} from "../Comment/ConnCommentCell";
import {AddButton} from "../AddButton";
import {ConnectedDeleteButton} from "../TableComponents/ConnectedDeleteButton";
import React from "react";
import {Link} from "react-router-dom";
import {ConnectedRestoreButton} from "../TableComponents/ConnectedRestoreButton";
import {updateObject} from "../common/objectStorage";
import {CarFilterState} from "../Car/types";
import {CAR_FILTER_SESSION_KEY} from "../Car/CarFiltering";
import {TyreSwapCell} from "./TyreSwapCell";
import {tyreDetailsColumns} from "./TyreDeailsColums";

export const columns = (
    showAddTyreModal: () => void,
    availableLocations: string[] | null,
    reload: () => void
): ColumnsType<ApiTyre> => [
    {
        key:       'label',
        dataIndex: 'label',
        width:     50,
        align:     "center",
        fixed:     'left',
        render(_, tyre) {
            return <PdfCell type="label" tyreId={tyre.id}/>
        },
    }, {
        key:       'license_plate',
        dataIndex: 'license_plate',
        title:     'Kenteken',
        width:     145,
        render(_, tyre) {
            return (tyre.car ?
                    <Link to="/car"
                          onClick={() => {
                              updateObject<CarFilterState>(CAR_FILTER_SESSION_KEY, () => ({
                                  searchIn:    ['license'],
                                  searchQuery: tyre.car!!.license_plate,
                              }));
                          }}
                          key={1} defaultValue={tyre.car.license_plate}>{tyre.car.license_plate}</Link> :
                    <span>N.B.</span>
            )
        }
    }, {
        key:       'customer',
        dataIndex: 'customer',
        title:     'Klantnaam',
        width:     165,
        render(_, tyre) {
            return (
                tyre.customer ? <Tooltip title={tyre.customer?.name}
                                         className={"module line-clamp-1"}>{tyre.customer?.name}</Tooltip> : "N.B."
            )
        }
    }, {
        ...hasEditableFields,
        key:       'location',
        dataIndex: 'location',
        title:     'Locatie',
        sorter:    true,
        width:     130,
        render(_, tyre) {
            return <ConnectedDropDownCell model={"tyres"} rowId={tyre.id} field={'location'}
                                          availableValues={availableLocations}
                                          initialValue={tyre.location.toString()}
                                          onChange={(value) => null}/>;
        }
    },
    ...tyreDetailsColumns(true),
    {
        ...hasEditableFields,
        key:       'last_intake_at',
        dataIndex: 'last_intake_at',
        title:     'Inname',
        width:     140,
        render(_, tyre) {
            return <ConnectedDatePickerCell model={"tyres"} rowId={tyre.id} field={'last_intake_at'}
                                            initialValue={tyre.last_intake_at}
                                            onChanged={(value) => null}/>
        }
    }, {
        ...hasEditableFields,
        key:       'packinglist',
        dataIndex: 'packinglist',
        title:     'Pakbon',
        width:     100,
        render(_, tyre) {
            return <ConnectedEditableCell model={"tyres"} rowId={tyre.id} field={'packinglist'}
                                          initialValue={tyre.packinglist?.toString() || ''}/>;
        },
    }, {
        key:       'status',
        dataIndex: 'status',
        title:     'Status',
        width:     100,
        render:    (_, tyre) => statusLabels[tyre.status]
    }, {
        key:       'comment',
        dataIndex: 'comment',
        title:     'Aantekening',
        width:     200,
        render(_, tyre) {
            let text = tyre.most_recent_comment ? tyre.most_recent_comment.comment.toString() : undefined;
            return (
                <ConnCommentCell comment={text} tyreId={tyre.id} reload={reload}/>
            );
        },
    }, {
        key:       'switch',
        dataIndex: 'switch',
        title:     '',
        width:     62,
        fixed:     "right",
        render(_, tyre) {
            return !tyre.deleted_at && tyre.car ?
                <TyreSwapCell carId={tyre.car!.id} reload={reload}/> : ''
        },
    }, {
        key:       'add',
        dataIndex: 'add',
        title:     <AddButton onClick={showAddTyreModal}/>,
        width:     62,
        fixed:     "right",
        render(_, tyre) {
            return tyre.deleted_at ? <ConnectedRestoreButton model='tyres' rowId={tyre.id} reload={reload}/> :
                <ConnectedDeleteButton model='tyres' rowId={tyre.id} reload={reload}/>
        }
    }];
