import React, {useState} from "react";
import {Button, Form, Input, notification} from "antd";
import useFetch from "use-http";
import {Redirect} from "react-router-dom";

import {apiEndpoint, getAccessToken, pathAfterLogin, storeAccessToken} from "./common/data";
import {MenuNavItem} from "./Header";
import {RedirectToLogin} from "./RedirectToLogin";
import {StyledBox} from "./StyledBox";

const labelColSpan = 7;

export type LoginFormPayload = {
  email: string;
  password: string;
};

const LoginForm: React.FC = () => {

  const {loading, post} = useFetch(apiEndpoint + '/auth/login');

  const [loggedIn, setLoggedIn] = useState(!!getAccessToken());

  if (window.location.hash === '#activation_success') {
    notification.success({
      message:     "Account geactiveerd",
      description: "U kunt nu inloggen."
    });
    return <RedirectToLogin/>
  }

  async function submit(payload: LoginFormPayload) {
    const login: {
      access_token: string,
      expires_in: number,
    } | {
      error: string,
    } = await post({...payload, bust_cache: Date.now()});
    if ('error' in login) {
      notification.error({message: login.error});
    } else {
      storeAccessToken(login.access_token, login.expires_in);
      setLoggedIn(true);
    }
  }

  if (loggedIn) return <Redirect to={pathAfterLogin()}/>;

  return <Form<LoginFormPayload>
    name="loginForm"
    onFinish={(values) => submit(values)}
    labelCol={{span: labelColSpan}}
    wrapperCol={{span: 24 - labelColSpan}}
    style={{
      maxWidth: 450,
      width:    'calc(100vw - 60px)',
    }}
  >
    <Form.Item
      label="E-mail"
      name="email"
      rules={[{required: true, message: 'Verplicht veld'}]}
    >
      <Input/>
    </Form.Item>
    <Form.Item
      label="Wachtwoord"
      name="password"
      rules={[{required: true, message: 'Verplicht veld'}]}
    >
      <Input.Password/>
    </Form.Item>
    <Form.Item wrapperCol={{offset: labelColSpan, span: 24 - labelColSpan}}>
      <Button
        disabled={loading}
        type="primary"
        htmlType="submit"
        style={{marginBottom: 2}}
      >
        Login
      </Button>
      <MenuNavItem path={'/passwordReset'} icon={undefined} iconScale={.95}>Wachtwoord vergeten</MenuNavItem>
    </Form.Item>
  </Form>
};

export const Login: React.FC = () => {
  return <StyledBox>
    <LoginForm/>
  </StyledBox>
}
