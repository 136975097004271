import React, {useState} from "react";
import {Modal, Spin} from "antd";
import {apiEndpoint, getDefaultHeaders} from "../common/data";
import {useFetch} from "use-http";
import {TyreSwapTable} from "./TyreSwapTable";

const TyreSwapModal: React.FC<{
  setModalState: (visibility: boolean, mustReload: boolean) => void,
  visibility: boolean,
  carId: number | string
}> = ({setModalState, visibility, carId}) => {

  const [reload, setReload] = useState<boolean>(false);

  const options: any = {
    cachePolicy: "no-cache",
    headers:     getDefaultHeaders(),
    method:      "GET"
  }

  const reloadPage = () => {
    setReload(!reload)
  };

  const BASE_URL = `${apiEndpoint}/cars/${carId}`

  const {loading, response, data = []} = useFetch(BASE_URL, options, [reload]);

  if (!loading && !response.ok) return <p>An error occurred.</p>;


  return <Modal
    visible={visibility}
    style={{top: 30}}
    title="Wissel banden"
    onCancel={() => setModalState(false, false)}
    footer={false}
    width={1200}
    afterClose={() => {
      setModalState(false, false)
    }}
  >
    {!loading ? <TyreSwapTable car={data} setReload={reloadPage}/> : <Spin/>}
  </Modal>
};

export default TyreSwapModal;

