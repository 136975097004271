import React from "react";
import {Modal} from "antd";
import {UserAddForm} from "./UserAddForm";
import {useUserProfile} from "../hooks/useUserProfile";

export const UserModal: React.FC<{
    modalVisible: boolean,
    setModalVisible: (value: boolean) => void,
    reload: () => void,
}> = ({modalVisible, setModalVisible, reload}) => {
    
    
    return (
        <Modal
            visible={modalVisible}
            style={{top: 30}}
            title="Nieuwe gebruiker"
            onCancel={() => setModalVisible(false)}
            footer={false}
        >
            <UserAddForm user={useUserProfile()} setModalVisibility={(value: boolean) => setModalVisible(value)}
                         reload={reload}/>
        </Modal>
    );
    
};