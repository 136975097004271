import React from "react";
import {Modal} from "antd";
import {CommentInputForm} from "./CommentInputForm";
import {CommentHistory} from "./CommentHistory";

export const CommentModal: React.FC<{
    visible: boolean,
    setVisibility: (value: boolean) => void,
    tyreId: number,
    reload: () => void,
}> = ({visible, setVisibility, tyreId, reload}) => {

    return (
        <Modal
            visible={visible}
            style={{top: 30}}
            title="Nieuwe aantekening"
            onCancel={() => setVisibility(false)}
            footer={false}
        >
            <CommentInputForm setModalVisibility={(value) => setVisibility(value)} row={tyreId} reload={reload}/>

            <div style={{margin: "10px", overflow: "auto", height: "60vh"}}>
                <CommentHistory tyreId={tyreId}/>
            </div>


        </Modal>

    );
};