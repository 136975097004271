import React, {CSSProperties, useCallback, useState} from "react";
import {apiEndpoint, getDefaultHeaders} from "../common/data";
import {useFetch} from "use-http";
import {Spin} from "antd";
import {CommentCell} from "./CommentCell";
import {ApiComment} from "../Tyre/types";

export function useComments(tyreId: number): { reload: () => void; comments: ApiComment[]; loading: boolean } {

    const [version, setVersion] = useState(0);
    const reload                = useCallback(() => setVersion(v => v + 1), [setVersion]);

    const BASE_URL             = `${apiEndpoint}/tyres/${tyreId}/comments`
    const {loading, data = []} = useFetch(BASE_URL, {
        cachePolicy: 'no-cache',
        headers:     getDefaultHeaders(),
    } as any, [version]);

    const comments = data?.data?.map((comment: any) => comment) || [];

    return {loading, reload, comments};
}

export function useCommentCount(tyreId: number): null | number {
    const {loading, comments} = useComments(tyreId);
    return loading ? null : comments.length;
}

export const CommentHistory: React.FC<{
    tyreId: number;
    style?: CSSProperties;
}> = ({tyreId, style}) => {

    const {loading, reload, comments} = useComments(tyreId);

    return loading ? <Spin/> : <div  style={{marginTop: 10, ...style}}>
        {comments?.length ? comments.map((c: ApiComment) =>
            <CommentCell key={c.id} comment={c} reload={reload}/>
        ) : undefined}
    </div>
};