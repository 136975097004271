import React, {ComponentProps, ReactNode, useEffect, useState} from "react";
import Search from "antd/es/input/Search";
import {CheckboxOptionList} from "./inputs/CheckboxOptionList";
import {toggle} from "./common/toggleInArray";
import {MultiSearchFilterState} from "./gridFiltering";

type MultiSearchBaseProps = {
    searchWord: string;
    searchIn: string[];
    setSearchIn: (search: string[]) => void;
    onSearch: (searchWord: string) => void;
    labels: Record<string, string>;
};

/**
 * Displays a search box and various checkboxes allowing the user to
 * specify in which field(s) to look for the search query.
 */
export const MultiSearch: React.FC<MultiSearchBaseProps & {
    query: string;
    setQuery: (query: string) => void;
    overrideOptionList?: (searchIn: string[]) => ReactNode;
}> = ({query, setQuery, searchWord, searchIn, onSearch, setSearchIn, labels, overrideOptionList}) => {

    const optionList = overrideOptionList?.(searchIn);

    useEffect(() => {
        // If searchWord changes, we need to update the query (intermediate value used for the input)
        // with it as well otherwise the field isn't cleared when filters are reset
        if (searchWord !== query) {
            setQuery(searchWord);
        }
    }, [searchWord]); /* eslint-disable-line react-hooks/exhaustive-deps */

    return <>
        <div style={{margin: '0 23px 6px'}}>
            <Search
                value={query}
                onChange={e => setQuery(e.target.value)}
                defaultValue={searchWord ? searchWord : undefined}
                placeholder="Zoekopdracht"
                onSearch={e => onSearch(e)}
                enterButton
            />
        </div>
        {optionList || <CheckboxOptionList
            labels={labels}
            selected={searchIn}
            setSelected={(item, selected) => setSearchIn(toggle(searchIn, item, selected))}
        />}
    </>
};

/**
 * Wrapper around `MultiSearch` component that interacts with the filter state given
 * the value and setter for some filter state that extends the base type `MultiSearchFilterState`.
 */
export function FilterStateMultiSearch<TFilter extends MultiSearchFilterState>(
    {
        overrideOptionList, value, onChange, labels,
    }: {
        value: TFilter;
        onChange: (value: TFilter) => void;
        labels: Record<string, string>;
    } & Pick<ComponentProps<typeof MultiSearch>, 'overrideOptionList'>) {

    // TODO: query wordt in de multisearch gebruikt,
    //  maar die wordt niet gereset met de reset filters lijkt het.
    const [query, setQuery] = useState(value.searchQuery || "");

    return <MultiSearch
        searchWord={value.searchQuery || ''}
        searchIn={value.searchIn || []}
        setSearchIn={(searchIn: string[]) => {
            onChange({...value, searchIn});
        }}
        onSearch={(query: string) => {
            onChange({...value, searchQuery: query});
        }}
        labels={labels}
        query={query}
        setQuery={setQuery}
        overrideOptionList={overrideOptionList}
    />
}
