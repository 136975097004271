import {ColumnType} from "antd/es/table";

/**
 * Provides `onHeaderCell` callback for columns that have editable fields.
 * The onHeaderCell applies a class that will be used to increase horizontal padding on the header
 * cell so that the header cell text aligns with the text in the fields within the column.
 */
export const hasEditableFields: Partial<ColumnType<any>> = {
    onHeaderCell() {
        return {
            className: 'has-editable-fields',
        };
    },
};
