import React, {useState} from "react";
import {ApiCar} from "../Car/types";
import {Table} from "antd";
import {columns} from "./TyreSwapColumns";
import {AddButton} from "../AddButton";
import {TyreModal} from "./TyreModal";

export const TyreSwapTable: React.FC<{
    car: ApiCar
    setReload: () => void
}> = ({car, setReload}) => {
    
    const [modalVisible, setModalVisible] = useState<boolean>(false)

    return <div>
        <TyreModal
            tyreModalVisible={modalVisible}
            setTyreModalVisibility={(value: boolean) => setModalVisible(value)}
            reload={() => setReload()}
            tyre={car.tyres[0]}
        />
        <div style={{display: "flex", marginBottom: 10}}>
            <span>Banden wissel scherm voor auto met kenteken {car.license_plate}</span>
            <span style={{marginLeft: "auto"}} ><AddButton onClick={() => setModalVisible(true)}/></span>
        </div>
        <Table
            size={'middle'}
            dataSource={car.tyres}
            columns={columns(() => setReload())}
            rowKey={tyre => tyre.id}
            pagination={false}
            scroll={{x: 1000}}
        />
    </div>
    
};
