import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {apiEndpoint, getDefaultFetchOptions} from "../common/data";
import useFetch from "use-http";
import {TablePaginationConfig} from "antd/es/table";
import {Table} from "antd";
import {CustomerModal} from "./CustomerModal";
import {updateObject} from "../common/objectStorage";
import {TYRE_FILTER_SESSION_KEY} from "../Tyre/tyreFiltering";
import {TyreFilterState} from "../Tyre/types";
import {customerGridColumns} from "./customerGridColumns";
import {CustomerFilterState} from "./types";
import {toQueryString} from "./customerFiltering";
import {GRID_PAGE_SIZE_OPTIONS} from "../common/constants";

const CustomerGrid: React.FC<{
    filters?: CustomerFilterState
}> = ({filters}) => {
    
    const history = useHistory();
    
    const [modalVisible, setModalVisible] = useState<boolean>(false)
    
    const [sort, setSort]               = useState<string>()
    const [page, setPage]               = useState<number>(1);
    const [pageSize, setPageSize]       = useState<number>(10)
    const [stateToggle, setStateToggle] = useState<boolean>(false);
    
    const filterQueryString = toQueryString(filters || {}, sort);
    
    
    const BASE_URL = `${apiEndpoint}/customers`;
    
    const options = getDefaultFetchOptions();
    
    const url = `${BASE_URL + (filterQueryString || '?')}&page_size=${pageSize}&page=${page}`;
    
    const {
              loading,
              response,
              data = [],
              abort,
          } = useFetch(url, options, [url, stateToggle]);
    
    useEffect(() => {
        if (page !== 1) {
            abort();
            setPage(1);
        }
    }, [filterQueryString]); // eslint-disable-line
    
    if (!loading && !response.ok) return <p>An error occurred.</p>;
    
    const pagination: TablePaginationConfig | undefined = data?.meta ? {
        current:         data.meta.current_page,
        pageSize:        data.meta.per_page,
        total:           data.meta.total,
        showSizeChanger: true,
        pageSizeOptions: GRID_PAGE_SIZE_OPTIONS,
    } : undefined;
    
    const handleChange = (pagination: any, filters: any, sorter: any) => {
        setPageSize(pagination.pageSize);
        var sorterToSetup = '';
        if (sorter.order)
            sorterToSetup = (sorter.order === "ascend" ? "-" : "") + sorter.field
        setSort(sorterToSetup)
        setPage(pagination.current)
    }
    const reload       = () => setStateToggle(!stateToggle);
    return (
        <div>
            <CustomerModal customerModalVisible={modalVisible}
                           setCustomerModalVisibility={(value) => setModalVisible(value)} reload={reload}/>
            <Table
                size={'middle'}
                loading={loading}
                columns={
                    customerGridColumns(() => setModalVisible(true),
                        reload,
                        (customer) => {
                            updateObject<TyreFilterState>(TYRE_FILTER_SESSION_KEY, () => ({
                                searchIn:    ['name_strict'],
                                searchQuery: customer.name,
                            }));
                            history.push(`/tyre`);
                        },
                    )
                }
                dataSource={loading ? undefined : data.data}
                pagination={pagination}
                onChange={handleChange}
                rowKey={tyre => tyre.id}
                scroll={{x: 1170}}
            />
        </div>
    );
};

export default CustomerGrid
