import React, {useState} from "react";
import {Tooltip} from "antd";
import {CommentModal} from "./CommentModal";

export const ConnCommentCell: React.FC<{
  comment: string | undefined,
  tyreId: number
  reload: () => void,
}> = ({comment, tyreId, reload}) => {

  const [visible, setVisibility] = useState<boolean>(false)

  return <div>
    <CommentModal visible={visible} setVisibility={(value) => setVisibility(value)} tyreId={tyreId} reload={reload}/>

    <div style={{cursor: "pointer"}} onClick={() => setVisibility(true)}>
      {comment ?
        <Tooltip title={comment}
                 className={"module line-clamp-2"}>
          {comment}
        </Tooltip> :
        <div style={{opacity: .3}}>
          Klik om toe te voegen
        </div>}
    </div>
  </div>
};
