import React from "react";
import {Layout} from "antd";

import {CompanyFilters} from "./CompanyFilters";
import {useSessionState} from "../hooks/useSessionState";
import {CompanyFilterState} from "./types";
import {COMPANY_FILTER_SESSION_KEY, DEFAULT_SEARCH_IN} from "./CompanyFiltering";
import {FilterFrame} from "../FilterFrame";
import {CompanyGrid} from "./CompanyGrid";

export const Company: React.FC = () => {
    
    const [filters, setFilters] = useSessionState<CompanyFilterState>(COMPANY_FILTER_SESSION_KEY, {
        searchIn: DEFAULT_SEARCH_IN,
    });
    
    return <Layout>
        <FilterFrame>
            <CompanyFilters
                value={filters} onChange={setFilters}/>
        </FilterFrame>
        <Layout>
            <CompanyGrid filters={filters}/>
        </Layout>
    </Layout>
    
}
