import React, {CSSProperties} from "react";
import {CheckboxOption} from "./CheckboxOption";

export const CheckboxOptionList: React.FC<{

    labels: string[] | Record<string, string>,
    selected: string[],
    setSelected: (item: string, selected: boolean) => void,
    style?: CSSProperties,

}> = ({labels, selected, setSelected, style}) => {

    // `labels` should be either a mapping of key to label, or just an array of keys so we'll
    // have to determine which one it is and find the array of keys accordingly
    const keys = Array.isArray(labels) ? labels : Object.keys(labels);

    return <div style={{
        marginBottom: 10,
        ...style,
    }}>{keys.map(key => <CheckboxOption
        selected={selected.includes(key)}
        onSelected={e => setSelected(key, e)}
        label={Array.isArray(labels) ? key : labels[key]}
        key={key}
    />)}</div>;
};
