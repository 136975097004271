import React from "react";
import {Menu, Switch} from "antd";
import {FilterFrameHeader} from "../FilterFrameHeader";
import {DeleteOutlined, SearchOutlined} from "@ant-design/icons";
import {UserFilterState} from "./types";
import {FilterStateMultiSearch} from "../MultiSearch";
import {DEFAULT_SEARCH_IN} from "./UserFiltering";

function isFiltersEmpty(value: UserFilterState): boolean {
    return !(value.searchQuery ||
        (value.searchIn?.length !== DEFAULT_SEARCH_IN.length) || //Double denial. if length = x, see that as if the filter is empty
        (value.trashed && value.trashed !== 'false')
    );
}

export const UserFilters: React.FC<{
    value: UserFilterState
    onChange: (value: UserFilterState) => void
}> = ({value, onChange}) => {
    
    const hasFilters = !isFiltersEmpty(value);
    
    const changeValue = (key: keyof UserFilterState) => (valueAtKey: any) => onChange({...value, [key]: valueAtKey});
    
    let showDeleted = !!value.trashed;
    
    return <Menu
        mode="inline"
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        style={{height: '100%'}}
    >
        <FilterFrameHeader
            onReset={hasFilters ? () => onChange({searchIn: DEFAULT_SEARCH_IN}) : undefined}
        >
            Gebruikers
        </FilterFrameHeader>
        <Menu.SubMenu style={{margin: '10px 0'}} key="sub1" icon={<SearchOutlined/>} title="Zoeken">
            <FilterStateMultiSearch value={value} onChange={onChange} labels={{
                email:   'Op e-mail',
                company: 'Op bedrijfsnaam',
            }}/>
        </Menu.SubMenu>
        <Menu.SubMenu style={{margin: '10px 0'}} key="filterDeleted" title="Verwijderd" icon={<DeleteOutlined/>}>
            <div style={{display: "flex"}}>
                <div style={{margin: '0 23px 16px'}}>
                    {/* // @ts-ignore*/}
                    <Switch checked={showDeleted} size="small" onChange={changeValue("trashed")}/>
                </div>
                <div style={{margin: '10px 23px 16px'}}>
                    Toon verwijderd
                </div>
            </div>
        </Menu.SubMenu>
    </Menu>
    
    
};