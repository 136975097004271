import React, {ComponentProps, useState} from "react";
import {DropDownCell} from "./DropDownCell";
import {apiEndpoint, getDefaultHeaders} from "../common/data";
import useFetch from "use-http";
import {apiModels} from "../common/types";


export const ConnectedDropDownCell: React.FC<Pick<ComponentProps<typeof DropDownCell>, 'getLabel'> & {
    model: apiModels;
    rowId: number;
    field: string;
    availableValues: string[] | null;
    initialValue: string;
    onChange?: (value: string) => void;
}> = ({model, rowId, field, availableValues, initialValue, onChange, getLabel}) => {
    
    const [initVal, setInitVal] = useState(initialValue);
    
    const options: any = {
        cachePolicy: 'no-cache',
        headers:     getDefaultHeaders()
    }
    
    const BASE_URL = `${apiEndpoint}/${model}/${rowId}`
    
    const {loading, put} = useFetch(BASE_URL, options);
    
    return <DropDownCell
        loading={loading}
        availableValues={availableValues}
        initialValue={initialValue}
        onChange={(value: string) => saveToDB(value)}
        getLabel={getLabel}
    />;
    
    async function saveToDB(value: string) {
        if (value !== initVal && rowId !== 0) {
            await put({[field]: value});
            setInitVal(value);
        } else {
            onChange?.(value)
        }
        
    }
    
}
