import React from "react";
import {Form} from "antd";

export const TyreFormLabel: React.FC<{
  label: string
}> = ({label}) => {
  return <Form.Item labelAlign={'right'} style={{
    marginTop:    -20,
    marginBottom: -8
  }}>
    <span style={{color: "gray"}}>{label}</span>
  </Form.Item>
};
