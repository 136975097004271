import React, {ReactNode} from "react";

/**
 * Covers the entire screen and centers the children in both directions
 */
export const SplashScreen: React.FC<{children: ReactNode}> = ({children}) => {
    return <div style={{
        display:        'flex',
        flexDirection:  'column',
        alignItems:     'center',
        justifyContent: 'center',
        minWidth:       '100vw', //full width
        minHeight:      '100vh', //full width
    }}>
        {children}
    </div>;
};
