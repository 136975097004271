import React, {useMemo, useState} from "react";
import {Table} from "antd";

import {UserGridColumns} from "./UserGridColumns";
import {apiEndpoint, getDefaultFetchOptions} from "../common/data";
import useFetch from "use-http";
import {useResetPageOnFilterChange} from "../hooks/useResetPageOnFilterChange";
import {TablePaginationConfig} from "antd/es/table";
import {GRID_PAGE_SIZE_OPTIONS} from "../common/constants";
import {toQueryString} from "./UserFiltering";
import {UserModal} from "./UserModal";
import {useUserProfile} from "../hooks/useUserProfile";

export const UserGrid: React.FC<{
    filters?: any
}> = ({filters}) => {
    
    const currentUser = useUserProfile();
    
    const [modalVisible, setModalVisible] = useState<boolean>(false)
    
    const [sort, setSort]               = useState<string>()
    const [page, setPage]               = useState<number>(1)
    const [pageSize, setPageSize]       = useState<number>(10)
    const [stateToggle, setStateToggle] = useState<boolean>(false)
    const filterQueryString             = toQueryString(filters || {}, sort);
    
    const BASE_URL = `${apiEndpoint}/users`;
    const options  = getDefaultFetchOptions();
    
    const url = `${BASE_URL + (filterQueryString || '?')}&page=${page}&page_size=${pageSize}`;
    
    
    const {loading, response, data = [], abort} = useFetch(url, options, [url, stateToggle]);
    
    useResetPageOnFilterChange(filterQueryString, abort, page, setPage);
    
    const pagination: TablePaginationConfig | undefined = useMemo(() => data?.meta ? {
        current:         data.meta.current_page,
        pageSize:        data.meta.per_page,
        total:           data.meta.total,
        showSizeChanger: true,
        pageSizeOptions: GRID_PAGE_SIZE_OPTIONS,
    } : undefined, [data]);
    
    if (!loading && !response.ok) return <p>An error occurred.</p>;
    
    /**
     * Pagination gets given o the request, and the backend knows which data to give.
     * To do the pagination, we need the backend to send how many pages there are available.
     * Then, on every click, keep notice of the page we are on.
     * @param pagination
     * @param filters
     * @param sorter
     */
    
    const handleChange = (pagination: any, filters: any, sorter: any) => {
        setPageSize(pagination.pageSize);
        let sorterToSetup = '';
        if (sorter.order)
            sorterToSetup = (sorter.order === "ascend" ? "-" : "") + sorter.field
        setSort(sorterToSetup)
        setPage(pagination.current)
    }
    
    const reload = () => setStateToggle(!stateToggle);
    
    return <div>
        <UserModal modalVisible={modalVisible} setModalVisible={setModalVisible} reload={reload}/>
        <Table
            size={'middle'}
            loading={loading}
            columns={UserGridColumns(() => setModalVisible(true), reload, currentUser)}
            dataSource={loading ? undefined : data.data}
            pagination={pagination}
            onChange={handleChange}
            rowKey={user => user.id}
            scroll={{x: 800}}
        />
    </div>
    
};