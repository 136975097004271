import React from "react";
import {Form, Input, notification} from "antd";
import {apiEndpoint, getDefaultHeaders} from "../common/data";
import useFetch from "use-http";
import {ModalFormFooter} from "../ModalFormFooter";
import {displayError} from "../displayError";

export const CompanyAddForm: React.FC<{
    setModalCompanyVisibility: (value: boolean) => void,
    reload: () => void,
}> = ({setModalCompanyVisibility, reload}) => {
    
    const [form] = Form.useForm();
    
    const BASE_URL = `${apiEndpoint}/companies`;
    
    const {loading, post, response} = useFetch(BASE_URL, {
        headers: getDefaultHeaders(),
    });
    
    async function saveToDB(): Promise<number> {
        form.getFieldsValue()
        const response = await post({
            "name": form.getFieldValue('companyName'),
            "kvk":  form.getFieldValue('kvk'),
        });
        if (response.id) return response.id;
        throw new Error(response.message || 'Could not save record');
    }
    
    async function validateAndSave(closeModal: boolean) {
        try {
            await form.validateFields();
            await saveToDB();
            
            form.resetFields();
            if (response.ok) {
                notification.success({
                    message: "Object opgeslagen",
                });
            } else {
                notification.error({
                    message:     "An error occurred",
                    description: response.statusText
                })
            }
            
            /**
             * closemodal = true => visibility = false => opposite
             */
            setModalCompanyVisibility(!closeModal);
            reload();
        } catch (e: any) {
            displayError(e);
        }
    }
    
    return <div>
        <Form
            form={form}
            id="customerForm"
            labelCol={{span: 6}}
            wrapperCol={{span: 16}}
            layout="horizontal"
        >
            <Form.Item label="Bedrijfsnaam" name="companyName"
                       rules={[{required: true, message: 'Dit veld is verplicht.'}]}>
                <Input/>
            </Form.Item>
            <Form.Item label="kvk" name="kvk" rules={[{required: true, message: 'Dit veld is verplicht.'}]}>
                <Input/>
            </Form.Item>
            <ModalFormFooter
                onCancel={() => setModalCompanyVisibility(false)}
                onSave={addAnother => validateAndSave(!addAnother)}
                loading={loading}
            />
        </Form>
    </div>
};
