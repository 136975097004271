import React, {ReactNode} from "react";
import {SplashScreen} from "./SplashScreen";
import {Logo} from "./Logo";

export const StyledBox: React.FC<{children: ReactNode}> = ({children}) => {
    return <SplashScreen>
        <div style={{
            background:   'white',
            borderRadius: 3,
            boxShadow:    '0 3px 6px rgba(0,0,0,0.1)',
            padding:      20,
        }}>
            <div style={{margin: '-10px 0 25px 35px'}}>
                <Logo clickable={false}/>
            </div>
            {children}
        </div>
    </SplashScreen>;
};
