import React from "react";
import {Layout} from "antd";

import {UserGrid} from "./UserGrid";
import {UserFilters} from "./UserFilters";
import {useSessionState} from "../hooks/useSessionState";
import {UserFilterState} from "./types";
import {DEFAULT_SEARCH_IN, USER_FILTER_SESSION_KEY} from "./UserFiltering";
import {FilterFrame} from "../FilterFrame";

export const Users: React.FC = () => {
    
    const [filters, setFilters] = useSessionState<UserFilterState>(USER_FILTER_SESSION_KEY, {
        searchIn: DEFAULT_SEARCH_IN,
    });
    
    return <Layout>
        <FilterFrame>
            <UserFilters
                value={filters} onChange={setFilters}/>
        </FilterFrame>
        <Layout>
            <UserGrid filters={filters}/>
        </Layout>
    </Layout>
}
