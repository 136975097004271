import React, {ReactNode, useState} from "react";
import {Select} from "antd";

export const DropDownCell: React.FC<{

  loading: boolean;
  availableValues: string[] | null;
  initialValue: any;
  onChange: (value: string) => void;
  getLabel?: (value: string) => ReactNode;

}> = ({loading, availableValues, initialValue, onChange, getLabel}) => {

  const [value, setValue] = useState(initialValue)

  const [editing, setEditing] = useState(false)

  return (
    <Select
      disabled={loading}
      // style={{width: 150}}
      value={value}
      bordered={editing}
      onFocus={() => setEditing(true)}
      showSearch={true}
      onChange={e => setValue(e)}
      onBlur={
        () => {
          setEditing(false)
          onChange(value)
        }
      }
      filterOption={(inputValue, option) => (option?.children || '').toString().toLowerCase().includes(inputValue.toLowerCase())}
    >
      {availableValues!.map(e =>
        <Select.Option value={e} key={e}>{getLabel ? getLabel(e) : e}</Select.Option>
      )}
    </Select>


  )
};
