import React, {useState} from "react";
import {Input} from "antd";


export const EditableCell: React.FC<{
    loading: boolean,
    initialValue: string,
    onChange: (value: string) => void,
}> = ({loading, initialValue, onChange}) => {

    const [editing, setEditing] = useState(false);

    const [value, setValue] = useState(initialValue);

    return <Input
        disabled={loading}
        readOnly={!editing}
        value={value}
        onChange={e => setValue(e.target.value)}
        onDoubleClick={() => setEditing(true)}
        onKeyPress={(event: any) => {
            //charCode 13 is the enter/return key
            if (event.charCode === 13) {
                toDBApiCall();
            }
        }}
        onBlur={() => {
            toDBApiCall();
        }}
        className={` editable ${loading ? 'loading' : ''} ${editing ? 'editing' : ''} `}
    />;

    function toDBApiCall() {
        setEditing(false)
        onChange(value)
    }
};