import React, {useEffect, useState} from "react";
import {useFetch} from "use-http";
import {apiEndpoint, getDefaultHeaders} from "../common/data";
import {ValuedDropDown} from "../TableComponents/ValuedDropDown";
import {apiModels} from "../common/types";

export const ConnCustomerDropDownCell: React.FC<{
    model: apiModels,
    rowId: number,
    field: string,
    selectedCustomerName?: string,
    initialValue: number | undefined,
    onChange: (value: number) => void,
}> = ({model, rowId, field, selectedCustomerName, initialValue, onChange}) => {
    
    const [editing, setEditing] = useState(false);
    
    const options: any = {
        cachePolicy: 'no-cache',
        headers:     getDefaultHeaders()
    }
    
    const {loading, get, data} = useFetch(apiEndpoint + `/customers?no_pagination=1`,
        options, [initialValue]);
    
    useEffect(() => {
        if (editing) {
            get();
        }
    }, [editing]); /* eslint-disable-line react-hooks/exhaustive-deps */
    
    const availableCustomers = data?.data || [];
    
    return <ValuedDropDown
        editing={initialValue ? editing : true}
        setEditing={setEditing}
        loading={loading}
        availableValues={availableCustomers}
        initialValue={initialValue}
        selectedValue={selectedCustomerName}
        onChange={value => onChange(value)}
        isNullable={false}
    />;
};
