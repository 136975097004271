import React, {useState} from "react";
import TextArea from "antd/es/input/TextArea";

export const EditableTextArea: React.FC<{
    loading: boolean,
    initialValue: string,
    onChange: (value: string) => void,
}> = ({loading, initialValue, onChange}) => {

    const [editing, setEditing] = useState(false);

    const [value, setValue] = useState(initialValue);

    return <TextArea
        autoSize={{minRows: 1, maxRows: 6}}
        disabled={loading}
        readOnly={!editing}
        value={value}
        onChange={e => setValue(e.target.value)}
        onDoubleClick={() => setEditing(true)}
        onBlur={() => {
            toDBApiCall();
        }}
        className={`editable ${loading ? 'loading' : ''} ${editing ? 'editing' : ''}`}
    />;

    function toDBApiCall() {
        setEditing(false)
        onChange(value)
    }
};