import React, {useMemo, useState} from "react";
import {Table} from "antd";
import useFetch from "use-http";
import {TablePaginationConfig} from "antd/es/table";

import {apiEndpoint, getDefaultFetchOptions} from "../common/data";
import {TyreModal} from "./TyreModal";
import {useLocations} from "../GlobalContextRoot";
import {TyreFilterState} from "./types";
import {toQueryString} from "./tyreFiltering";
import {useResetPageOnFilterChange} from "../hooks/useResetPageOnFilterChange";
import {GRID_PAGE_SIZE_OPTIONS} from "../common/constants";
import {columns} from "./TyreGridColumns";


const TyreGrid: React.FC<{
    filters?: TyreFilterState;
}> = ({filters}) => {
    
    const [modalVisible, setModalVisible] = useState<boolean>(false)
    
    const availableLocations            = useLocations();
    const [sort, setSort]               = useState<string>()
    const [page, setPage]               = useState<number>(1)
    const [pageSize, setPageSize]       = useState<number>(10)
    const [stateToggle, setStateToggle] = useState<boolean>(false)
    const filterQueryString             = toQueryString(filters || {}, sort);
    
    const BASE_URL = `${apiEndpoint}/tyres`;
    
    const options = getDefaultFetchOptions();
    
    const url = `${BASE_URL + (filterQueryString || '?')}&page=${page}&page_size=${pageSize}`;
    
    const {loading, response, data = [], abort} = useFetch(url, options, [url, stateToggle]);
    
    useResetPageOnFilterChange(filterQueryString, abort, page, setPage);
    
    const pagination: TablePaginationConfig | undefined = useMemo(() => data?.meta ? {
        current:         data.meta.current_page,
        pageSize:        data.meta.per_page,
        total:           data.meta.total,
        showSizeChanger: true,
        pageSizeOptions: GRID_PAGE_SIZE_OPTIONS,
    } : undefined, [data]);
    
    if (!loading && !response.ok) return <p>An error occurred.</p>;
    
    /**
     * pagination wordt meegegeven in de Table, die regelt de gegevens verder.
     * we moeten eerst weten hoeveel pagina's de pagination heeft, dan kan de pagination onderin alvast worden geladen.
     * daarna wordt per klik bijgehouden waar we zijn.
     * @param pagination
     * @param filters
     * @param sorter
     */
    
    const handleChange = (pagination: any, filters: any, sorter: any) => {
        setPageSize(pagination.pageSize);
        let sorterToSetup = '';
        if (sorter.order)
            sorterToSetup = (sorter.order === "ascend" ? "-" : "") + sorter.field
        setSort(sorterToSetup)
        setPage(pagination.current)
    }
    
    const reload = () => setStateToggle(!stateToggle);
    
    return (
        <div>
            <TyreModal
                tyreModalVisible={modalVisible}
                setTyreModalVisibility={(value: boolean) => setModalVisible(value)}
                reload={reload}
            />
            <Table
                size={'middle'}
                loading={loading}
                columns={columns(
                    () => setModalVisible(true),
                    availableLocations,
                    reload)
                }
                dataSource={loading ? undefined : data.data}
                pagination={pagination}
                onChange={handleChange}
                rowKey={tyre => tyre.id}
                scroll={{x: 1350}}
            />
        </div>
    );
}

export default TyreGrid;
