import React, {useEffect, useState} from "react";
import {useFetch} from "use-http";
import {apiEndpoint, getDefaultHeaders} from "../common/data";
import {ValuedDropDown} from "../TableComponents/ValuedDropDown";

export const ConnCompanyDropDownCell: React.FC<{
    model: "users",
    rowId: number,
    field: string,
    selectedCompanyName?: string,
    initialValue: number | undefined,
    onChange: (value: number) => void,
}> = ({model, rowId, field, selectedCompanyName, initialValue, onChange}) => {
    
    const [initKey, setInitialKey] = useState<number | undefined>(initialValue)
    const [editing, setEditing]    = useState(false);
    
    const {loading, get, data} = useFetch(apiEndpoint + `/companies?no_pagination=1`, {
        headers: getDefaultHeaders(),
    });
    
    //if the useEffect used editing, the dropdown automatically collapsed sometimes
    useEffect(() => {
        if (editing) {
            get();
        }
    }, [editing]); /* eslint-disable-line react-hooks/exhaustive-deps */
    
    const options: any = {
        cachePolicy: 'no-cache',
        headers:     getDefaultHeaders()
    }
    
    const {put} = useFetch(apiEndpoint + `/${model}/${rowId}`, options);
    
    //must be != because value is not seen as a number?
    async function saveToDB(companyId: number) {
        // eslint-disable-next-line
        if (companyId != initKey) {
            await put({
                [field]: companyId,
            });
            setInitialKey(companyId)
        }
    }
    
    const availableCustomers = data?.data || [];
    
    return <ValuedDropDown
        editing={initialValue ? editing : true}
        setEditing={setEditing}
        loading={loading}
        availableValues={availableCustomers}
        initialValue={initialValue}
        selectedValue={selectedCompanyName}
        onChange={(value: number) => {
            onChange(value);
            if (initialValue) saveToDB(value)
        }}
        isNullable={false}
    />;
};
