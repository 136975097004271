import React from "react";
import {Alert, Button} from "antd";

export const SpecialSearchAlert: React.FC<{
    fieldLabel: string;
    reset?: () => void;
}> = ({fieldLabel, reset}) => {
    return <Alert
        message={<span style={{fontSize: 13}}>Speciale zoekopdracht</span>}
        description={<div style={{fontSize: 12}}>
            <p style={{opacity: .7, marginBottom: '.1rem'}}>Exacte overeenkomst ({fieldLabel})</p>
            <Button
                htmlType={'button'}
                type={'link'}
                style={{padding: 0, fontSize: 12, marginTop: -4, marginLeft: -1}}
                onClick={reset}
            >
                Handmatig zoeken
            </Button>
        </div>}
        style={{margin: '12px 24px 10px', padding: '5px 10px'}}
    />;
}
