import React from "react";
import {FilterStateMultiSearch} from "../MultiSearch";
import {FilterFrameHeader} from "../FilterFrameHeader";
import {Menu, Switch} from "antd";
import {DeleteOutlined, SearchOutlined} from "@ant-design/icons";
import {CarFilterState} from "./types";
import {DEFAULT_SEARCH_IN} from "./CarFiltering";


function isFiltersEmpty(value: CarFilterState): boolean {
    return !(value.searchQuery ||
        (value.minTyres ? value.minTyres >= 1 : false) ||
        (value.maxTyres ? value.maxTyres <= 9 : false) ||
        (value.searchIn?.length !== DEFAULT_SEARCH_IN.length) ||
        (value.trashed && value.trashed !== 'false')
    );
}

export const CarFilters: React.FC<{
    value: CarFilterState
    onChange: (value: CarFilterState) => void
}> = ({value, onChange}) => {

    const hasFilters = !isFiltersEmpty(value);

    const changeValue = (key: keyof CarFilterState) => (valueAtKey: any) => onChange({...value, [key]: valueAtKey});

    let showDeleted = !!value.trashed;

    return <Menu
        mode="inline"
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        style={{height: '100%'}}
    >
        <FilterFrameHeader onReset={
            hasFilters ? () => onChange({searchIn: DEFAULT_SEARCH_IN}) : undefined}
        >
            Auto's
        </FilterFrameHeader>
        <Menu.SubMenu style={{margin: '10px 0'}} key="sub1" icon={<SearchOutlined/>} title="Zoeken">
            <FilterStateMultiSearch value={value} onChange={onChange} labels={{
                license: 'Op kenteken',
                brand:   'Op merk',
                type:    'Op type',
                name:    'Op klantnaam'
            }}/>
        </Menu.SubMenu>
        <Menu.SubMenu style={{margin: '10px 0'}} key="filterDeleted" title="Verwijderd" icon={<DeleteOutlined/>}>
            <div style={{display: "flex"}}>
                <div style={{margin: '0 23px 16px'}}>
                    {/* // @ts-ignore*/}
                    <Switch checked={showDeleted} size="small" onChange={changeValue("trashed")}/>
                </div>
                <div style={{margin: '10px 23px 16px'}}>
                    Toon verwijderd
                </div>
            </div>
        </Menu.SubMenu>
    </Menu>
};
