import React from "react";
import {CustomerFilterState} from "./types";
import {Menu, Slider, Switch} from "antd";
import {FilterFrameHeader} from "../FilterFrameHeader";
import {FilterStateMultiSearch} from "../MultiSearch";
import {DeleteOutlined, SearchOutlined} from "@ant-design/icons";
import {CustomIcon} from "../icons/CustomIcon";
import {CUSTOMER_TYRES_SLIDER_RANGE} from "./constants";
import {DEFAULT_SEARCH_IN} from "./customerFiltering";
import {SpecialSearchAlert} from "../SpecialSearchAlert";

function isFiltersEmpty(value: CustomerFilterState): boolean {
    return !(value.searchQuery ||
        (value.minTyres ? value.minTyres >= 1 : false) ||
        (value.maxTyres ? value.maxTyres <= 9 : false) ||
        (value.searchIn?.length !== DEFAULT_SEARCH_IN.length) ||
        (value.trashed && value.trashed !== 'false')
    );
}

export const CustomerFilters: React.FC<{
    value: CustomerFilterState;
    onChange: (value: CustomerFilterState) => void;
}> = ({value, onChange}) => {

    const hasFilters = !isFiltersEmpty(value);

    const changeValue = (key: keyof CustomerFilterState) => (valueAtKey: any) =>
        onChange({...value, [key]: valueAtKey});

    let showDeleted = !!value.trashed;

    return <Menu
        mode="inline"
        defaultOpenKeys={['tyres', 'search']}
        style={{height: '100%'}}
    >
        <FilterFrameHeader onReset={hasFilters ? () => onChange({
            searchIn: DEFAULT_SEARCH_IN
        }) : undefined}
        >
            Klanten
        </FilterFrameHeader>

        <Menu.SubMenu
            key={'tyres'}
            title="Banden"
            style={{marginTop: 10}}
            icon={<CustomIcon icon={"tyre-stack"} style={{
                transform:   'translate(-4px, 2px)',
                marginRight: 5,
            }}/>}
        >
            <div style={{
                margin: '0 23px',
            }}>
                <Slider
                    onAfterChange={range => onChange({
                        ...value,
                        minTyres: range[0],
                        maxTyres: range[1],
                    })}
                    range
                    defaultValue={[0, CUSTOMER_TYRES_SLIDER_RANGE]}
                    min={0}
                    max={CUSTOMER_TYRES_SLIDER_RANGE}
                    tipFormatter={v => (v || 0) >= CUSTOMER_TYRES_SLIDER_RANGE ? `${CUSTOMER_TYRES_SLIDER_RANGE} of meer` : v}
                />
            </div>
        </Menu.SubMenu>
        <Menu.SubMenu key={'search'} style={{marginTop: 10}} icon={<SearchOutlined/>} title={"Zoeken"}>
            <FilterStateMultiSearch
                value={value}
                onChange={onChange}
                labels={{
                    name:         'Op naam',
                    postalcode:   'Op postcode',
                    phone_number: 'Op tel. nummer',
                }}
                overrideOptionList={searchIn => {
                    if (searchIn[0] === 'name_strict') return <SpecialSearchAlert
                        reset={() => onChange({...value, searchIn: DEFAULT_SEARCH_IN})}
                        fieldLabel={'naam'}
                    />
                }}
            />
        </Menu.SubMenu>
        <Menu.SubMenu style={{margin: '10px 0'}} key="filterDeleted" title="Verwijderd" icon={<DeleteOutlined/>}>
            <div style={{display: "flex"}}>
                <div style={{margin: '0 23px 16px'}}>
                    <Switch checked={showDeleted} size="small" onChange={changeValue("trashed")}/>
                </div>
                <div style={{margin: '10px 23px 16px'}}>
                    Toon verwijderd
                </div>
            </div>
        </Menu.SubMenu>

    </Menu>
};
