import React from "react";
import {NavLink} from "react-router-dom";
import logo from "./images/logo_3d_no_text.png";

import "./Logo.scss";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

export const Logo: React.FC<{
    clickable?: boolean;
    responsive?: boolean;
    withVersion?: boolean;
}> = ({clickable = true, responsive = true, withVersion = true}) => {

    const {md} = useBreakpoint();

    const collapse = responsive && !md;

    const Tag        = clickable ? NavLink : 'div';
    const props: any = clickable ? {to: "/"} : {};

    return <Tag {...props} className={`logo ${collapse ? 'compact' : ''}`}>
        <img alt={'BANDENBASE'} src={logo}/>
        <div className={'logo-right'}>
            <div className={'wordmark'}>
                <span className={'brand'}>
                    <strong style={{fontWeight: 900}}>BANDEN</strong>
                    <span style={{display: 'inline-block', transform: 'scale(.6)'}}>•</span>
                    BAAS
                </span>
                <span className={'tagline'}>
                    grip op bandenopslag
                </span>
            </div>
            {withVersion ?
                <span className={'version'}>
                v2.3.0
            </span> : null}
        </div>
    </Tag>;
}
