import React from "react";
import {ResetPasswordForm} from "./ResetPasswordForm";
import {StyledBox} from "../StyledBox";

export const PasswordReset: React.FC = () => {
    
    return <StyledBox>
        <ResetPasswordForm/>
    </StyledBox>
    
};
