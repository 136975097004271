import React, {useState} from "react";
import {Form, Input, notification, Tooltip} from "antd";
import {apiEndpoint, getDefaultHeaders} from "../common/data";
import useFetch from "use-http";
import {ModalFormFooter} from "../ModalFormFooter";
import {displayError} from "../displayError";
import {AddButton} from "../AddButton";
import {ConnCompanyDropDownCell} from "../Company/ConnCompanyDropDownCell";
import {CompanyModal} from "../Company/CompanyModal";
import {RolesMultiSelect} from "./RolesMultiSelect";
import {UserProfile} from "../hooks/useUserProfile";

export const UserAddForm: React.FC<{
    user?: UserProfile
    setModalVisibility: (value: boolean) => void,
    reload: () => void,
}> = ({user, setModalVisibility, reload}) => {
    
    let roles = user?.roles.map((data: any) => data.name);
    
    let mustShowAllCompanies = !!roles?.includes('super_admin');
    
    const [companyModalVisibility, setCompanyModalVisibility] = useState<boolean>(false)
    
    const [form] = Form.useForm();
    
    const BASE_URL = `${apiEndpoint}/users`;
    
    const {loading, post, response} = useFetch(BASE_URL, {
        headers: getDefaultHeaders(),
    });
    
    async function saveToDB(): Promise<number> {
        form.getFieldsValue()
        const response = await post({
            "email":          form.getFieldValue('email'),
            "password":       form.getFieldValue('password'),
            "password_again": form.getFieldValue('password_again'),
            "company_id":     form.getFieldValue('company'),
            "roles":          form.getFieldValue('roles'),
        });
        if (response.id) return response.id;
        throw new Error(response.message || 'Could not save record');
    }
    
    async function validateAndSave(closeModal: boolean) {
        try {
            await form.validateFields()
            await saveToDB();
            form.resetFields();
            if (response.ok) {
                notification.success({
                    message: "Object opgeslagen",
                });
            } else {
                notification.error({
                    message:     "An error occurred",
                    description: response.statusText
                })
            }
            
            /**
             * closemodal = true => visibility = false => opposite
             */
            setModalVisibility(!closeModal);
            reload();
        } catch (e: any) {
            displayError(e);
        }
    }
    
    return <div>
        
        <CompanyModal companyModalVisible={companyModalVisibility}
                      setCompanyModalVisible={(value) => setCompanyModalVisibility(value)} reload={reload}/>
        
        <Form
            form={form}
            id="customerForm"
            labelCol={{span: 6}}
            wrapperCol={{span: 16}}
            layout="horizontal"
        >
            <Form.Item label="Email" name="email" rules={[{required: true, message: 'Dit veld is verplicht.'}]}>
                <Input/>
            </Form.Item>
            <Form.Item label="Wachtwoord" name="password"
                       rules={[
                           {required: true, message: 'Dit veld is verplicht.'},
                           {pattern: /(?=.*?[A-Z])/, message: "Wachtwoord moet minimaal één hoofdletter bevatten"},
                           {pattern: /(?=.*?[a-z])/, message: "Wachtwoord moet minimaal één kleine letter bevatten"},
                           {pattern: /(?=.*?[0-9])/, message: "Wachtwoord moet minimaal één cijfer bevatten"},
                           {
                               pattern: /(?=.*?[#?!@$%^&*-])/,
                               message: "Wachtwoord moet minimaal één speciaal teken bevatten"
                           },
                           {min: 8, message: "Wachtwoord moet minimaal 8 tekens lang zijn"}
                           //Minimum of 8 characters. 1 capital character, 1 lowercase, 1 digit, and 1 symbol
                       ]}>
                <Input.Password/>
            </Form.Item>
            <Form.Item label="Wachtwoord nogmaals" name="password_again"
                       rules={[{required: true, message: 'Dit veld is verplicht.'}]}>
                <Input.Password/>
            </Form.Item>
            {
                mustShowAllCompanies ?
                    <Form.Item label="Bedrijf" name="company"
                               rules={[{required: true, message: 'Dit veld is verplicht.'}]}>
                        <div style={{display: 'flex'}}>
                            <div style={{marginRight: 10, flex: 1}}>
                                <ConnCompanyDropDownCell model={"users"} rowId={0} field={"company"}
                                                         initialValue={undefined}
                                                         onChange={(value) => form.setFieldsValue({'company': value})}/>
                            </div>
                            <Tooltip title="Nieuwe klant toevoegen">
                                <AddButton onClick={() => setCompanyModalVisibility(true)}/>
                            </Tooltip>
                        </div>
                    </Form.Item>
                    : <></>
            }
            <Form.Item label="Extra rechten" name="roles">
                <RolesMultiSelect
                    editing={true}
                    onChange={(value) => form.setFieldsValue({'roles': value})}
                    setEditing={(value: boolean) => undefined}
                    defaultValues={[]}
                    rowId={0}
                />
            
            </Form.Item>
            <ModalFormFooter
                onCancel={() => setModalVisibility(false)}
                onSave={addAnother => validateAndSave(!addAnother)}
                loading={loading}
            />
        </Form>
    </div>
};
