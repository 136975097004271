import React from "react";
import {Button, notification, Popconfirm} from "antd";
import {useFetch} from "use-http";
import {apiEndpoint, getDefaultHeaders} from "../common/data";
import {DeleteTwoTone, setTwoToneColor} from "@ant-design/icons";
import {apiModels} from "../common/types";


export const ConnectedDeleteButton: React.FC<{
    model: apiModels,
    rowId: number,
    reload: () => void,
}> = ({model, rowId, reload}) => {
    
    const {del, response} = useFetch(apiEndpoint, {
        headers: getDefaultHeaders()
    });
    
    const forgeDestroy = async () => {
        notification.close('warning');
        await del(`/${model}/${rowId}/forge`)
        response.ok ? notifyDelete() : showWarning();
    }
    
    function notifyDelete() {
        notification.success({message: "record verwijderd"});
        reload();
    }
    
    function showWarning() {
        let btn = <Button type="primary" onClick={forgeDestroy}>JA</Button>
        if (response.data.action === "showWarning") {
            notification.open({
                message:  response.data.message,
                btn,
                duration: 5,
                key:      'warning',
            });
            return;
        }
        notification.error({message: response.statusText});
    }
    
    const handleOk = async () => {
        await del(`/${model}/${rowId}`)
        response.ok ? notifyDelete() : showWarning();
    }
    
    setTwoToneColor("#d12c2c")
    
    return <Popconfirm
        title="Verwijder?"
        onConfirm={handleOk}
    >
        <Button style={{marginLeft: 'auto', display: 'block'}}><DeleteTwoTone/>
        
        </Button>
    </Popconfirm>
};
