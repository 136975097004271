import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";

import About from "./About";
import Car from "./Car/Car"
import Tyre from "./Tyre/Tyre";
import {Sandbox} from "./Sandbox";
import Customer from "./Customer/Customer"
import {Login} from "./Login";
import {Logout} from "./Logout";
import {PageRoot} from "./PageRoot";
import {Users} from "./Users/Users";
import {Company} from "./Company/Company";
import {PasswordReset} from "./Auth/PasswordReset";
import {NewPassword} from "./Auth/NewPassword";

function App() {
    return <Router>
        <Switch>
            <Route path="/login" component={Login}/>
            <Route path="/passwordreset" component={PasswordReset}/>
            <Route path="/newpassword" component={NewPassword}/>
            <Route component={() => <PageRoot>
                <Switch>
                    <Route path="/logout" component={Logout}/>
                    <Route path="/customer" component={Customer}/>
                    <Route path="/tyre/:id?" component={Tyre}/>
                    <Route path="/car" component={Car}/>
                    <Route path="/users" component={Users}/>
                    <Route path="/companies" component={Company}/>
                    <Route path="/about" component={About}/>
                    <Route path="/sandbox" component={Sandbox}/>
                    <Redirect path={"/"} exact={true} to={"/tyre"}/>
                </Switch>
            </PageRoot>}/>
        </Switch>
    </Router>;
}

export default App;
