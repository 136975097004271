import React from "react";
import {FilePdfOutlined} from "@ant-design/icons";

import {apiEndpoint, getAccessToken} from "../common/data";

export const PdfCell: React.FC<{
    type: string
    tyreId: number
}> = ({tyreId, type}) => {

    return <div style={{
        paddingLeft: 10,
    }}><a
        href={`${apiEndpoint}/${type}/${tyreId}?token=${getAccessToken()}`}
        target="_blank"
        rel="noopener noreferrer"
    ><FilePdfOutlined/></a></div>
};
