import React, {useState} from "react";
import {apiEndpoint, getDefaultHeaders} from "../common/data";
import {useFetch} from "use-http";
import {TyreUpdateForm} from "./TyreUpdateForm";
import {Modal, Spin} from "antd";
import {useParams} from "react-router-dom";

export const TyreUpdateModal: React.FC<{
  visibility: boolean,
}> = ({visibility}) => {

  const [visible, setVisible] = useState<boolean>(true)
  const [state, toggleState]  = useState<boolean>(false)

  const reload = () => toggleState(!state)

  let {id} = useParams<{ id: string }>()

  const options: any = {
    cachePolicy: "no-cache",
    headers: getDefaultHeaders(),
    method: "GET"
  }

  const BASE_URL                              = `${apiEndpoint}/tyres/${id}`
  const {loading, response, data = []} = useFetch(BASE_URL, options, []);

  if (!loading && !response.ok) return <p>An error occurred.</p>;

  return  <Modal
    visible={visible}
    style={{top: 30}}
    title="Update band"
    onCancel={() => setVisible(false)}
    footer={false}
  >
    {!loading?<TyreUpdateForm rowId={parseInt(id)} tyre={data}
                              setTyreModalVisibility={(value: boolean) => setVisible(value)} reload={reload}/>:<Spin/>}
  </Modal>
};
