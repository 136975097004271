import React, {ReactNode} from "react";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {Collapse, Layout} from "antd";

import {FileSearchOutlined} from "@ant-design/icons";

/**
 * Automatically positioning frame that acts as a sidebar on sufficiently wide screens,
 * and a vertically collapsible section on narrower screens.
 */
export const FilterFrame: React.FC<{children: ReactNode}> = ({children}) => {
    const {md, lg} = useBreakpoint();

    return md ? <Layout.Sider
        className="site-layout-background"
        width={lg ? 240 : 200}
        style={{
            marginRight: 24,
            height:      'fit-content',
        }}
    >
        {children}
    </Layout.Sider> : <Layout
        className={'layout-block'}
        style={{marginBottom: 24}}
    >
        <Collapse ghost>
            <Collapse.Panel showArrow={false} key={'1'} header={<div style={{padding: '10px'}}>
                <FileSearchOutlined style={{margin: '0 9px 0 2px'}}/>
                <span style={{textDecoration: 'underline'}}>Toon/verberg filters</span>
            </div>}>
                <div style={{
                    margin: '-10px -17px 0 -16px',
                }}>
                    {children}
                </div>
            </Collapse.Panel>
        </Collapse>
    </Layout>;
};
