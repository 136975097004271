import React from "react";
import {ColumnsType} from "antd/es/table";
import {Button} from "antd";

import {ApiCustomer} from "./types";
import {hasEditableFields} from "../common/headerCell";
import {ConnectedEditableCell} from "../TableComponents/ConnectedEditableCell";
import {AddButton} from "../AddButton";
import {ConnectedDeleteButton} from "../TableComponents/ConnectedDeleteButton";
import {ConnectedRestoreButton} from "../TableComponents/ConnectedRestoreButton";
import {MailCell} from "../TableComponents/MailCell";
import {CallToCell} from "../TableComponents/CallToCell";

export const customerGridColumns = (
    showAddCustomerModal: () => void,
    reload: () => void,
    showTyres: (customer: ApiCustomer) => void,
): ColumnsType<ApiCustomer> => ([
    {
        ...hasEditableFields,
        key:       'name',
        dataIndex: 'name',
        title:     "Naam",
        width:     200,
        render(_, customer) {
            return <ConnectedEditableCell model={"customers"} rowId={customer.id} field={'name'}
                                          initialValue={customer.name.toString()}/>;
        },
    }, {
        key:       'tyre_count',
        dataIndex: 'tyre_count',
        title:     "Banden",
        width:     70,
        render(_, customer) {
            return <Button
                onClick={() => showTyres(customer)}
                type={'link'}
                style={{marginLeft: -15}}
            >{customer.tyre_count}</Button>;
        },
    }, {
        ...hasEditableFields,
        key:       'postalcode',
        dataIndex: 'postalcode',
        title:     'Postcode',
        width:     100,
        render(_, customer) {
            return <ConnectedEditableCell model={"customers"} rowId={customer.id} field={'postalcode'}
                                          initialValue={customer.postalcode.toString()}/>
            
        },
    }, {
        ...hasEditableFields,
        key:       'city',
        dataIndex: 'city',
        title:     'Stad',
        width:     150,
        render(_, customer) {
            return <ConnectedEditableCell model={"customers"} rowId={customer.id} field={'city'}
                                          initialValue={customer.city?.toString() || ''}/>
            
        },
    }, {
        ...hasEditableFields,
        key:       'address',
        dataIndex: 'street',
        title:     'Adres',
        width:     150,
        render(_, customer) {
            return <ConnectedEditableCell model={"customers"} rowId={customer.id} field={'street'}
                                          initialValue={customer.street?.toString() || ''}/>
            
        },
    }, {
        ...hasEditableFields,
        key:       'housenumber',
        dataIndex: 'housenumber',
        title:     'Huisnummer',
        width:     120,
        render(_, customer) {
            return <ConnectedEditableCell model={"customers"} rowId={customer.id} field={'housenumber'}
                                          initialValue={customer.housenumber.toString()}/>
        }
    }, {
        ...hasEditableFields,
        key:       'phone_number',
        dataIndex: 'phone_number',
        title:     'Telefoonnr.',
        width:     150,
        render(_, customer) {
            return <div>
                <CallToCell customer={customer}
                            phoneNumber={customer.phone_number ? customer.phone_number.toString() : ""}/>
            </div>;
        },
    }, {
        ...hasEditableFields,
        key:       'contact_person',
        dataIndex: 'contact_person',
        title:     'Contactpersoon',
        width:     160,
        render(_, customer) {
            return <ConnectedEditableCell model={"customers"} rowId={customer.id} field={'contact_person'}
                                          initialValue={(customer.contact_person ? customer.contact_person.toString() : "")}/>;
        },
    }, {
        ...hasEditableFields,
        key:       'email',
        dataIndex: 'email',
        title:     'Email',
        width:     160,
        render(_, customer) {
            return <MailCell customer={customer}/>
            
        },
    }, {
        key:       'add',
        dataIndex: 'add',
        title:     <AddButton onClick={showAddCustomerModal}/>,
        width:     62,
        fixed:     "right",
        render(_, customer) {
            return customer.deleted_at ?
                <ConnectedRestoreButton model='customers' rowId={customer.id} reload={reload}/> :
                <ConnectedDeleteButton model='customers' rowId={customer.id} reload={reload}/>
            
        }
    }
]);
