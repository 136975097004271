import React, {ComponentProps} from "react";
import {Layout, Menu} from "antd";
import {NavLink, useLocation} from "react-router-dom"

import {CustomIcon} from "./icons/CustomIcon";

import "./Header.scss";
import {Logo} from "./Logo";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {useUserProfile} from "./hooks/useUserProfile";
import {getAdminMenuNode} from "./Admin/AdminMenu";

export const MenuNavItem: React.FC<{
    path: string,
    icon?: ComponentProps<typeof CustomIcon>['icon'],
    iconScale?: number,
    children: any
}> = ({path, icon, iconScale = 1, children}) => {
    return <NavLink className="menu-item-link" exact activeClassName="active" to={path}>
        {icon ? <CustomIcon style={{transform: `scale(${iconScale})`, maxWidth: 20}} icon={icon}/> : null}
        {children}
    </NavLink>;
}


export const Header: React.FC = () => {
    const location    = useLocation();
    const {xs}        = useBreakpoint();
    const userProfile = useUserProfile();
    
    const items = [
        <Menu.Item key="/tyre" className={'compact-menu-item'}>
            <MenuNavItem path={'/tyre'} icon={'tyre-stack'} iconScale={.95}>Banden</MenuNavItem>
        </Menu.Item>,
        <Menu.Item key="/car" className={'compact-menu-item'}>
            <MenuNavItem path={'/car'} icon={'car'} iconScale={1.05}>Auto's</MenuNavItem>
        </Menu.Item>,
        <Menu.Item key="/customer" className={'compact-menu-item'}>
            <MenuNavItem path={'/customer'} icon={"address-card"} iconScale={.9}>Klanten</MenuNavItem>
        </Menu.Item>,
        getAdminMenuNode(userProfile),
        <Menu.Item key="/logout" style={{marginLeft: 'auto'}} className={'nav-menu-logout'}>
            <MenuNavItem path={'/logout'} icon={'logout'} iconScale={.8}>
                <div style={{
                    lineHeight:     'normal',
                    height:         65,
                    display:        'flex',
                    flexDirection:  'column',
                    justifyContent: 'center'
                }}>
                    <strong>{userProfile?.email}</strong>
                    <span style={{opacity: .7}}>Uitloggen</span>
                </div>
            </MenuNavItem>
        </Menu.Item>,
    ];
    
    return <Layout.Header className="header" style={xs ? {paddingLeft: 20, paddingRight: 20} : {}}>
        <Logo withVersion={false}/>
        <Menu theme="light" mode="horizontal" selectedKeys={[location.pathname]} style={{display: 'flex', minWidth: 0}}>
            {items}
        </Menu>
    </Layout.Header>;
};
