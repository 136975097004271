import React from "react";
import {Modal} from "antd";
import {TyreAddForm} from "./TyreAddForm";
import {ApiTyre} from "./types";


export const TyreModal: React.FC<{
    tyreModalVisible: boolean,
    setTyreModalVisibility: (value: boolean) => void,
    reload: () => void,
    tyre? :ApiTyre
}> = ({tyreModalVisible, setTyreModalVisibility, reload,tyre}) => {
    
    return (
        <Modal
            visible={tyreModalVisible}
            style={{top: 30}}
            title="Nieuwe band"
            onCancel={() => setTyreModalVisibility(false)}
            footer={false}
        >
            <TyreAddForm setTyreModalVisibility={(value: boolean) => setTyreModalVisibility(value)} reload={reload} tyre={tyre}/>
        </Modal>
    );
}
