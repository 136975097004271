import React, {CSSProperties} from "react";
import {Button} from "antd";

export const ModalFormFooter: React.FC<{
    onCancel: () => void,
    onSave: (addAnother: boolean) => void,
    loading: boolean,
    saveOnly?: boolean,
    style?: CSSProperties,
}> = ({onCancel, onSave, loading,saveOnly, style}) => {

    return <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
        ...style,
    }}>
        <Button type={"link"} form="customerForm" key="cancel" onClick={onCancel}>
            Annuleren
        </Button>
        <Button

            loading={loading}
            type={"primary"}
            style={{margin: '0 10px'}}
            form="customerForm"
            key="save"
            onClick={() => onSave(false)}
            htmlType="submit"
        >
            Opslaan
        </Button>
        {!saveOnly ?
            <Button
                loading={loading}
                type={"primary"}
                form="customerForm"
                key="save_and_again"
                onClick={() => onSave(true)}
            >
                Opslaan en nog een toevoegen
            </Button> : ""}
    </div>;
};
