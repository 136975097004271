import React, {useEffect} from "react";
import {forgetAccessToken} from "./common/data";
import {Redirect} from "react-router-dom";

export const Logout: React.FC = () => {
    useEffect(() => {
        forgetAccessToken();
        window.location.reload(); //To reset all session data
    }, []);
    return <Redirect to={"/login"}/>;
};