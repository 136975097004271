import React, {useState} from "react";
import TyreSwapModal from "./TyreSwapModal";
import {RetweetOutlined} from "@ant-design/icons";
import {Button} from "antd";

export const TyreSwapCell: React.FC<{
  carId: number,
  reload: () => void
}> = ({carId, reload}) => {

  const [modalVisibility, setVisibility] = useState<boolean>(false)

  const setModalState = (visibility: boolean, mustReload: boolean) => {
    setVisibility(visibility)
    if (mustReload) {
      reload();
    }
  }

  return <>
    <TyreSwapModal
      setModalState={(visibility, mustReload) => setModalState(visibility, mustReload)}
      visibility={modalVisibility}
      carId={carId}
    />
    <Button onClick={() => setVisibility(true)}><RetweetOutlined style={{color: '#1890ff'}}/></Button>
  </>;
};
