import React from "react";
import {Form, Input, notification} from "antd";
import {apiEndpoint, getDefaultHeaders} from "../common/data";
import useFetch from "use-http";
import {ModalFormFooter} from "../ModalFormFooter";
import {displayError} from "../displayError";

export const CarAddForm: React.FC<{
    setModalVisibility: (value: boolean) => void,
    reload: () => void,
}> = ({setModalVisibility, reload}) => {

    const [form] = Form.useForm();

    const BASE_URL = `${apiEndpoint}/cars`;

    const {loading, post, response} = useFetch(BASE_URL, {
        headers: getDefaultHeaders(),
    });

    async function saveToDB(): Promise<number> {
        form.getFieldsValue()
        const response = await post({
            "customer_id": form.getFieldValue('customer'),
            "license_plate": form.getFieldValue('license_plate'),
            "brand": form.getFieldValue('brand'),
            "type": form.getFieldValue('type'),
        });
        if (response.id) return response.id;
        throw new Error(response.message || 'Could not save record');
    }

    //TODO: IF-ELSE en try-catch voelt samen niet helemaal lekker. navragen
    // ook duplicate code met de andere forms
    async function validateAndSave(closeModal: boolean) {
        try {
            await form.validateFields();
            await saveToDB();

            form.resetFields();
            if (response.ok) {
                notification.success({
                    message: "Object opgeslagen",
                });
            } else {
                notification.error({
                    message: "An error occurred",
                    description: response.statusText
                })
            }

            /**
             * closemodal = true => visibility = false => opposite
             */
            setModalVisibility(!closeModal);
            reload();
        } catch (e: any) {
            displayError(e);
        }
    }

    return <div>
        <Form
            form={form}
            id="customerForm"
            labelCol={{span: 6}}
            wrapperCol={{span: 16}}
            layout="horizontal"
        >
            <Form.Item label="Kenteken" name="license_plate" rules={[{required: true, message: 'Dit veld is verplicht.'}]}>
                <Input/>
            </Form.Item>
            <Form.Item label="Merk" name="brand" rules={[{required: true, message: 'Dit veld is verplicht.'}]}>
                <Input/>
            </Form.Item>
            <Form.Item label="Type" name="type" rules={[{required: true, message: 'Dit veld is verplicht.'}]}>
                <Input/>
            </Form.Item>

            <ModalFormFooter
                onCancel={() => setModalVisibility(false)}
                onSave={addAnother => validateAndSave(!addAnother)}
                loading={loading}
            />
        </Form>
    </div>
};
