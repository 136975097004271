import {useEffect} from "react";

/**
 * Implements an effect that will invoke the "abort" function and, on the next tick, "setPage" function with `1`
 * whenever "queryString" changes, given that the current page is not `1` at that point.
 * @param queryString
 * @param abort
 * @param currentPage
 * @param setPage
 */
export function useResetPageOnFilterChange(
    queryString: string,
    abort: () => void,
    currentPage: number | undefined,
    setPage: (page: number) => void
): void {
    useEffect(() => {
        if (currentPage !== 1) {
            abort();
            setTimeout(() => {
                setPage(1);
            }, 0);
        }
    }, [queryString]); // eslint-disable-line
}
