import React, {useState} from "react";
import {Select} from "antd";

export const ValuedDropDown: React.FC<{
  loading: boolean
  availableValues: any
  initialValue: number | undefined,
  selectedValue?: string,
  onChange: (value: number) => void,
  setEditing: (editing: boolean) => void,
  editing: boolean,
  isNullable: boolean,
}> = ({loading, availableValues, initialValue, selectedValue, onChange, setEditing, editing, isNullable}) => {

  const [value, setValue] = useState<number | undefined>(initialValue)

  return <Select
    style={{width: '100%'}}
    notFoundContent={loading ? <span>Wordt geladen...</span> : undefined}
    loading={loading}
    value={value}
    bordered={!value ? true : editing}
    onClick={() => setEditing(true)}
    showSearch={true}
    onChange={(e) => {
      setValue(e);
      onChange(e);
    }}
    onBlur={() => setEditing(false)}
    filterOption={(inputValue, option) => (option?.children || '').toString().toLowerCase().includes(inputValue.toLowerCase())}
  > {isNullable ? <Select.Option value={0} key={0}>N.B</Select.Option> : null}
    {availableValues?.length ? availableValues.map((e: any) =>
      <Select.Option value={e.id} key={e.id}>{e.name}</Select.Option>
    ) : undefined}
    {(value && !availableValues?.length) ? <Select.Option value={value}>{selectedValue}</Select.Option> : null}
  </Select>;

};
