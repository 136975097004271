import React, {useMemo, useState} from "react";
import {Table} from "antd";
import {ColumnsType, TablePaginationConfig} from "antd/es/table";
import {useFetch} from "use-http";

import {ConnectedEditableCell} from "../TableComponents/ConnectedEditableCell";
import {ConnectedDeleteButton} from "../TableComponents/ConnectedDeleteButton";
import {apiEndpoint, getDefaultFetchOptions} from "../common/data";
import {CarModal} from "./CarModal";
import {hasEditableFields} from "../common/headerCell";
import {AddButton} from "../AddButton";
import {updateObject} from "../common/objectStorage";
import {Link} from 'react-router-dom';
import {useResetPageOnFilterChange} from "../hooks/useResetPageOnFilterChange";
import {ApiCar, CarFilterState} from "./types";
import {toQueryString} from "./CarFiltering";
import {TyreFilterState} from "../Tyre/types";
import {TYRE_FILTER_SESSION_KEY} from "../Tyre/tyreFiltering";
import {ConnectedRestoreButton} from "../TableComponents/ConnectedRestoreButton";
import {GRID_PAGE_SIZE_OPTIONS} from "../common/constants";
import {CustomerFilterState} from "../Customer/types";
import {CUSTOMER_FILTER_SESSION_KEY} from "../Customer/customerFiltering";

const colums = (showAddCarModal: () => void, reload: () => void): ColumnsType<ApiCar> => ([
    {
        ...hasEditableFields,
        key:       'license_plate',
        dataIndex: 'license_plate',
        title:     "Kenteken",
        render(_, car) {
            return <ConnectedEditableCell model={"cars"} rowId={car.id} field={'license_plate'}
                                          initialValue={car.license_plate.toString()}/>;
        },
    }, {
        ...hasEditableFields,
        key:       'brand',
        dataIndex: 'brand',
        title:     'Merk',
        render(_, car) {
            return <ConnectedEditableCell model={"cars"} rowId={car.id} field={'brand'}
                                          initialValue={car.brand.toString()}/>;
        },
    }, {
        ...hasEditableFields,
        key:       'type',
        dataIndex: 'type',
        title:     'Type',
        render(_, car) {
            return <ConnectedEditableCell model={"cars"} rowId={car.id} field={'type'}
                                          initialValue={car.type.toString()}/>;
        },
    }, {
        key:       'tyre',
        dataIndex: 'tyre',
        title:     'Banden',
        render(_, car) {
            return car.tyres ? <Link to="/tyre" onClick={() => {
                updateObject<TyreFilterState>(TYRE_FILTER_SESSION_KEY, () => ({
                    searchIn:    ['license'],
                    searchQuery: car.license_plate,
                }));
            }}
                                     key={1}
                                     defaultValue={car.tyre_count}
            >{car.tyre_count}</Link> : "N.B."
        }
    }, {
        key:       'customer',
        dataIndex: 'customer',
        title:     'Klant',
        render(_, car) {
            return car.customer ?
                <Link to="/customer"
                      onClick={() => {
                          updateObject<CustomerFilterState>(CUSTOMER_FILTER_SESSION_KEY, () => ({
                              searchIn:    ['name_strict'],
                              searchQuery: car.customer.name
                          }));
                      }}
                >{car.customer.name}</Link> :
                "N.B."
        }
    }, {
        key:       'add',
        dataIndex: 'add',
        title:     <AddButton onClick={showAddCarModal}/>,
        width:     62,
        fixed:     "right",
        render(_, car) {
            return car.deleted_at ? <ConnectedRestoreButton model='cars' rowId={car.id} reload={reload}/> :
                <ConnectedDeleteButton model='cars' rowId={car.id} reload={reload}/>
        }
    }
])

export const CarGrid: React.FC<{
    filters: CarFilterState,
}> = ({filters}) => {
    
    const [modalVisible, setModalVisible] = useState(false)
    const [sort, setSort]                 = useState<string>()
    const [page, setPage]                 = useState<number>(1)
    const [pageSize, setPageSize]         = useState<number>(10)
    const [stateToggle, setStateToggle]   = useState<boolean>(false)
    
    const filterQueryString = toQueryString(filters || {}, sort);
    
    const BASE_URL = `${apiEndpoint}/cars`;
    const options  = getDefaultFetchOptions();
    const url      = `${BASE_URL + (filterQueryString || '?')}&page_size=${pageSize}&page=${page}`;
    const {
              loading,
              response,
              data = [],
              abort,
          }        = useFetch(url, options, [url, stateToggle]);
    
    useResetPageOnFilterChange(filterQueryString, abort, page, setPage);
    
    const pagination: TablePaginationConfig | undefined = useMemo(() => data?.meta ? {
        current:         data.meta.current_page,
        pageSize:        data.meta.pageSize,
        total:           data.meta.total,
        showSizeChanger: true,
        pageSizeOptions: GRID_PAGE_SIZE_OPTIONS,
    } : undefined, [data]);
    
    if (!loading && !response.ok) {
        return (<div><p>An error occurred.</p>
            <p>{response.status} {response.statusText}</p></div>)
    }
    
    const handleChange = (pagination: any, filters: any, sorter: any) => {
        setPageSize(pagination.pageSize);
        var sorterToSetup = '';
        if (sorter.order)
            sorterToSetup = (sorter.order === "ascend" ? "-" : "") + sorter.field
        setSort(sorterToSetup)
        setPage(pagination.current)
    }
    
    const reload = () => setStateToggle(!stateToggle);
    
    
    return (
        <div>
            
            <CarModal carModalVisible={modalVisible} setCarModalVisible={(value: boolean) => setModalVisible(value)}
                      reload={reload}/>
            
            <Table
                size={'middle'}
                loading={loading}
                columns={colums(() => setModalVisible(true), reload)}
                dataSource={loading ? undefined : data.data}
                pagination={pagination}
                onChange={handleChange}
                rowKey={tyre => tyre.id}
                scroll={{x: 600}}
            />
        </div>
    );
};
