import React, {useState} from "react";
import {apiEndpoint, availableSeasons, getDefaultHeaders, seasonLabels} from "../common/data";
import useFetch from "use-http";
import {Checkbox, Form, Input, InputNumber, notification, Select, Tooltip} from "antd";
import {CustomerModal} from "../Customer/CustomerModal";
import {ModalFormFooter} from "../ModalFormFooter";
import {CarModal} from "../Car/CarModal";
import {useLocations} from "../GlobalContextRoot";
import {ConnCustomerDropDownCell} from "../Customer/ConnCustomerDropDownCell";
import {ConnLicensePlateDropDownCell} from "../Car/ConnLicensePlateDropDownCell";
import {ConnectedDatePickerCell} from "../TableComponents/ConnectedDatePickerCell";
import {AddButton} from "../AddButton";
import {displayError} from "../displayError";
import {ApiTyre} from "./types";
import {TyreFormLabel} from "./TyreFormLabel";

import './tyre.css';

export const TyreAddForm: React.FC<{
  setTyreModalVisibility: (value: boolean) => void,
  reload: () => void;
  tyre?: ApiTyre
}> = ({setTyreModalVisibility, reload, tyre}) => {

  const [customerModalVisibility, setCustomerModalVisibility] = useState<boolean>(false)
  const [carModalVisibility, setCarModalVisibility]           = useState<boolean>(false);
  const [withCounterpart, setWithCounterPart]                 = useState<boolean>(!tyre);

  const availableLocations: string[] = useLocations()

  const BASE_URL = `${apiEndpoint}/tyres`;

  const {post, loading, response} = useFetch(BASE_URL, {
    headers: getDefaultHeaders(),
  });

  async function saveToDB() {
    let createDouble = {};
    if (!tyre) {
      createDouble = {'withCounterpart': form.getFieldValue('counterpart') ?? 'true'}
    }

    await post({
      "customer_id":    form.getFieldValue('customer'),
      "location":       form.getFieldValue('location'),
      "row":            form.getFieldValue('row'),
      "pile":           form.getFieldValue('pile'),
      "season":         form.getFieldValue('season'),
      "size":           form.getFieldValue('size'),
      "size_2":         form.getFieldValue('size_2'),
      "tread_depth":    form.getFieldValue('tread_depth'),
      "tread_depth_2":  form.getFieldValue('tread_depth_2'),
      "container":      form.getFieldValue('container'),
      "packinglist":    form.getFieldValue('packinglist'),
      "last_intake_at": form.getFieldValue('last_intake_at'),
      "car_id":         form.getFieldValue('license_plate'),
      ...createDouble
    })
  }

  const [form] = Form.useForm();

  async function validateAndSave(closeModal: boolean) {
    try {
      // await form.validateFields();
      await saveToDB();
      form.resetFields();
      if (response.ok) {
        notification.success({
          message: "Object opgeslagen",
        });
      } else {
        notification.error({
          message:     "An error occurred",
          description: response.statusText
        })
      }
      /**
       * closemodal = true => visibility = false => opposite
       */
      setTyreModalVisibility(!closeModal);
      reload();
    } catch (e: any) {
      displayError(e);
    }
  }

  if (tyre) {
    form.setFieldsValue({'customer': tyre.customer_id});
    form.setFieldsValue({'license_plate': tyre.car_id});
    form.setFieldsValue({'location': tyre.location});
    form.setFieldsValue({'row': tyre.row});
    form.setFieldsValue({'pile': tyre.pile});
  }


  return <div>

    <CustomerModal customerModalVisible={customerModalVisibility}
                   setCustomerModalVisibility={(value) => setCustomerModalVisibility(value)} reload={reload}/>

    <CarModal carModalVisible={carModalVisibility}
              setCarModalVisible={(value: boolean) => setCarModalVisibility(value)} reload={reload}/>
    <Form
      form={form}
      id="tyreForm"
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      layout="horizontal"
    >
      <Form.Item label="Klant" name="customer"
                 rules={[{required: true, message: "Dit veld is verplicht.."}]}
      >
        <div style={{display: 'flex'}}>
          <div style={{marginRight: 10, flex: 1}}>
            <ConnCustomerDropDownCell model={"tyres"} rowId={0} field={"customer"}
                                      initialValue={tyre?.customer_id ? tyre.customer_id : undefined}
                                      onChange={(value) => form.setFieldsValue({'customer': value})}/>
          </div>
          <Tooltip title="Nieuwe klant toevoegen">
            <AddButton onClick={() => setCustomerModalVisibility(true)}/>
          </Tooltip>
        </div>
      </Form.Item>
      <Form.Item key="season" label="Seizoen" name="season"
                 rules={[{required: true, message: "Dit veld is verplicht.."}]}
      >
        <Select>
          {availableSeasons.map(season =>
            <Select.Option key={season} value={season}>{seasonLabels[season]}</Select.Option>
          )}
        </Select>
      </Form.Item>
      <Form.Item key="location" label="Locatie" name="location"
                 rules={[{required: true, message: "Dit veld is verplicht.."}]}
      >
        <Select>
          {availableLocations.map(location =>
            <Select.Option key={location} value={location}>{location}</Select.Option>
          )}
        </Select>
      </Form.Item>
      <Form.Item label="Rij" name="row">
        <Input/>
      </Form.Item>
      <Form.Item label="Stapel" name="pile">
        <InputNumber style={{width: '100%'}} min={1}/>
      </Form.Item>

      <span>
        {withCounterpart ? <TyreFormLabel label='Band 1'/> : undefined}
        <Form.Item label="Details" name="size"
                   rules={[{required: true, message: "Dit veld is verplicht.."}]}
        >
          <Input/>
        </Form.Item>
        <Form.Item label="Profiel" name="tread_depth" className={withCounterpart ? '-mt-15' : ''}
                   rules={[{required: true, message: "Dit veld is verplicht.."}]}>
        <Input/>
        </Form.Item>
      </span>
      {withCounterpart ?
        <span>
          <TyreFormLabel label='Band 2'/>
          <Form.Item label="Details" name="size_2">
            <Input/>
          </Form.Item>
          <Form.Item label="Profiel" name="tread_depth_2" className={withCounterpart ? '-mt-15' : ''}>
            <Input/>
          </Form.Item>
        </span> :
        undefined}

      <Form.Item label="Pakbon" name="packinglist"
                 rules={[{required: true, message: "Dit veld is verplicht.."}]}
      >
        <Input/>
      </Form.Item>
      <Form.Item label="Kenteken" name="license_plate"
                 rules={[{required: true, message: "Dit veld is verplicht.."}]}
      >
        <div style={{display: 'flex'}}>
          <div style={{marginRight: 10, flex: 1}}>
            <ConnLicensePlateDropDownCell
              initialValue={tyre?.car_id ? tyre.car_id : undefined}
              onChange={(value) => form.setFieldsValue({'license_plate': value})}
            />
          </div>
          <Tooltip title="Nieuwe auto toevoegen">
            <AddButton onClick={() => setCarModalVisibility(true)}/>
          </Tooltip>
        </div>
      </Form.Item>
      <Form.Item label="Innamedatum" name="last_intake_at">
        <ConnectedDatePickerCell model={"tyres"} rowId={0} field={'last_intake_at'}
                                 initialValue={new Date(Date.now()).toISOString()}
                                 onChanged={(value) => {
                                   form.setFieldsValue({'last_intake_at': value})
                                 }}/>
      </Form.Item>
      {!tyre ? <Form.Item style={{height: 50}} label="Beide (Zomer/Winter)" name="counterpart">
        <Checkbox onChange={(e) => {
          setWithCounterPart(e.target.checked)
          form.setFieldsValue({'counterpart': e.target.checked ? 'true' : 'false'})
        }} defaultChecked={true}>
          Maak de zomer/winter tegenhanger aan.
        </Checkbox>
      </Form.Item> : ''}
      <ModalFormFooter
        onCancel={() => setTyreModalVisibility(false)}
        onSave={addAnother => validateAndSave(!addAnother)}
        loading={loading}
      />
    </Form>
  </div>
};
