import {useFetch} from "use-http";
import {apiEndpoint, getDefaultHeaders} from "../common/data";
import {ApiCompany} from "../Company/types";

export type UserProfile = {
    email: string,
    id: number,
    company: ApiCompany
    permissions: string[]
    roles: string[]
    rolesCustom: string[]
}

export function useUserProfile(): UserProfile | undefined {
    const {data} = useFetch(apiEndpoint + `/auth/user-profile`, {
        headers: getDefaultHeaders(),
    }, []);
    return data;
}

