import React from "react";
import {ColumnsType} from "antd/es/table";
import {ConnectedEditableCell} from "../TableComponents/ConnectedEditableCell";
import {hasEditableFields} from "../common/headerCell";
import {ApiCompany} from "./types";
import {AddButton} from "../AddButton";
import {ConnectedRestoreButton} from "../TableComponents/ConnectedRestoreButton";
import {ConnectedDeleteButton} from "../TableComponents/ConnectedDeleteButton";
import {Button} from "antd";

export const CompanyGridColumns = (
    showAddCompanyModal: () => void,
    reload: () => void,
    showUsers: (company: ApiCompany) => void,
): ColumnsType<ApiCompany> => ([
    {
        ...hasEditableFields,
        key:       'name',
        dataIndex: 'name',
        title:     "Naam",
        render(_, company) {
            return <ConnectedEditableCell model={'companies'} rowId={company.id} field={'name'}
                                          initialValue={company.name.toString()}/>;
        },
    }, {
        ...hasEditableFields,
        key:       'kvk',
        dataIndex: 'kvk',
        title:     "KVK",
        render(_, company) {
            return <ConnectedEditableCell model={'companies'} rowId={company.id} field={'kvk'}
                                          initialValue={company.kvk.toString()}/>;
        },
    }, {
        ...hasEditableFields,
        key:       'user_count',
        dataIndex: 'user_count',
        title:     "Aantal gebruikers",
        render(_, company) {
            return <Button
                onClick={() => showUsers(company)}
                type={'link'}
                style={{marginLeft: -15}}
            >{company.user_count}</Button>;
        },
    }, {
        key:       'add',
        dataIndex: 'add',
        title:     <AddButton onClick={showAddCompanyModal}/>,
        width:     62,
        fixed:     "right",
        render(_, company) {
            return company.deleted_at ?
                <ConnectedRestoreButton model='companies' rowId={company.id} reload={reload}/> :
                <ConnectedDeleteButton model='companies' rowId={company.id} reload={reload}/>
        }
    }
])