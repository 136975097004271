import buildUrl from "build-url";

import {CarFilterState} from "./types";
import {getFilterQuery} from "../gridFiltering";

export const CAR_FILTER_SESSION_KEY = 'CAR_FILTERS';
export const DEFAULT_SEARCH_IN = ['license', 'brand', 'type', 'name'];

export function toQueryString(filter: CarFilterState, sort?: string): string {
    return buildUrl('', {
        queryParams: {
            ...(sort ? {sort} : {}),
            'filter[special]': getFilterQuery(filter) || '',
            'filter[trashed]': filter.trashed?"only":"false" || '',
        },
    });
}
