import React, {useState} from "react";
import {EditableCell} from "./EditableCell";
import useFetch from "use-http";
import {apiEndpoint, getDefaultHeaders} from "../common/data";
import {apiModels} from "../common/types";

export const ConnectedEditableCell: React.FC<{
    model: apiModels,
    rowId: number,
    field: string,
    initialValue: string,
    onChange?: (value: string) => void | undefined
}> = ({model, rowId, field, initialValue, onChange}) => {
    
    const [initVal, setInitVal] = useState(initialValue);
    
    const BASE_URL = `${apiEndpoint}/${model}/${rowId}`
    
    const options: any = {
        cachePolicy: 'no-cache',
        headers:     getDefaultHeaders(),
    };
    
    const {loading, put} = useFetch(BASE_URL, options);
    
    return <EditableCell
        loading={loading}
        initialValue={initialValue}
        onChange={(value: string) => saveToDB(value)}
    />;
    
    async function saveToDB(value: string) {
        if (value !== initVal) {
            await put({[field]: value})
            setInitVal(value);
            if (onChange) {
                onChange(value);
            }
        }
    }
};
