import React from "react";
import {Modal} from "antd";
import {CarAddForm} from "./CarAddForm";

export const CarModal: React.FC<{
    carModalVisible: boolean,
    setCarModalVisible: (value: boolean) => void
    reload: () => void,
}> = ({carModalVisible, setCarModalVisible, reload}) => {

    return (
        <Modal
            visible={carModalVisible}
            style={{top: 30}}
            title="Nieuwe auto"
            onCancel={() => setCarModalVisible(false)}
            footer={false}
        >
            <CarAddForm setModalVisibility={(value: boolean) => setCarModalVisible(value)} reload={reload}/>
        </Modal>
    );

};