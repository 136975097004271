import {notification} from "antd";

/**
 * Display error notification and print stack trace to the console for diagnostics
 */
export function displayError(e: Error) {
  notification.error({
    message:     "An error occurred",
    description: e.message,
  });
  console.warn(e.message);
  console.warn(e.stack);
}
