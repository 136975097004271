/**
 * Ensure the given item is either absent from or present in the given array
 * based on the value of the `present` parameter.
 */
export function toggle<T>(set: T[], item: T, present: boolean): T[] {
    if (!present) {
        return set.filter(i => i !== item);
    } else {
        return set.includes(item) ? set : [...set, item];
    }
}
