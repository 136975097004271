import {get} from 'lodash';

export function updateObject<TObject = any>(key: string, updater: (current: TObject) => TObject): TObject {
    const currentValue = JSON.parse(sessionStorage.getItem(key) || '{}');
    const newValue     = updater(currentValue);
    sessionStorage.setItem(key, JSON.stringify(newValue));
    return newValue;
}

export function getFromObject<TReturn = any>(key: string, field: string, def: TReturn): TReturn {
    const currentValue = JSON.parse(sessionStorage.getItem(key) || '{}');
    return get(currentValue, field, def);
}
