import React from "react";
import {Layout} from "antd";

import {CarGrid} from "./CarGrid";
import {CarFilters} from "./CarFilters";
import {FilterFrame} from "../FilterFrame";
import {useSessionState} from "../hooks/useSessionState";
import {CarFilterState} from "./types";
import {CAR_FILTER_SESSION_KEY, DEFAULT_SEARCH_IN} from "./CarFiltering";

const Car: React.FC = () => {

    const [filters, setFilters] = useSessionState<CarFilterState>(CAR_FILTER_SESSION_KEY, {searchIn: DEFAULT_SEARCH_IN});

    return <Layout>
        <FilterFrame>
            <CarFilters
                value={filters} onChange={setFilters}
            />
        </FilterFrame>
        <Layout>
            <CarGrid filters={filters}/>
        </Layout>
    </Layout>
}

export default Car;
