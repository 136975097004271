import React, {ReactNode} from "react";
import {Menu} from "antd";
import {UserProfile} from "../hooks/useUserProfile";
import {MenuNavItem} from "../Header";
import SubMenu from "antd/es/menu/SubMenu";
import {CustomIcon} from "../icons/CustomIcon";
import {MANAGE_COMPANIES, MANAGE_USERS} from "../common/constants";

function canDo(user: UserProfile | undefined, actions: string[]) {
    return user?.permissions.some(permission => actions.includes(permission))
}

export function getAdminMenuNode(userProfile: UserProfile | undefined): ReactNode {
    
    const canManageUsers     = canDo(userProfile, MANAGE_USERS)
    const canManageCompanies = canDo(userProfile, MANAGE_COMPANIES)
    
    const canDoAdminActions = canManageUsers || canManageCompanies //if only one of them is true, the user has permission to do some admin actions
    
    if (!canDoAdminActions)
        return null
    
    return <SubMenu key={"adminMenu"} title={"Admin"} className='compact-menu-item'
                    icon={<CustomIcon style={{transform: `scale(${0.9})`}} icon={"admin"}/>}
    >
        {
            canManageUsers ?
                <Menu.Item key={'/users'} className={'compact-menu-item'}>
                    <MenuNavItem path={'/users'} icon={"users"}
                                 iconScale={.9}>Gebruikers</MenuNavItem>
                </Menu.Item> :
                undefined
        }
        {
            canManageCompanies ?
                <Menu.Item key={'/companies'} className={'compact-menu-item'}>
                    <MenuNavItem path={'/companies'} icon={"address-card"}
                                 iconScale={.9}>Bedrijven</MenuNavItem>
                </Menu.Item> :
                undefined
        }
    </SubMenu>;
}