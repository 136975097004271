import React, {useState} from "react";
import {Button, Form, Input, notification} from "antd";
import {RedirectToLogin} from "../RedirectToLogin";
import useFetch from "use-http";
import {apiEndpoint} from "../common/data";

const labelColSpan = 7

type setNewPasswordForm = {
    password: string,
    password_again: string,
    token: string
}

export const NewPasswordForm: React.FC = () => {
    
    const [loading, setLoading]   = useState<boolean>(false)
    const [redirect, setRedirect] = useState<boolean>(false)
    
    const {post} = useFetch(apiEndpoint + '/auth/setNewPassword');
    
    const queryParams = new URLSearchParams(window.location.search);
    const token       = queryParams.get('id');
    if (!token)
        return <RedirectToLogin pathAfterLogin={'/tyre'}/>
    
    async function submit(payload: setNewPasswordForm, token: string) {
        setLoading(true)
        payload.token = token
        
        const reset: {
            password: string,
            password_again: string,
            token: string,
        } | {
            message: string,
            errors: { token: string[] }
        } = await post({...payload, bust_cache: Date.now()});
        if ('message' in reset) {
            if (reset.message === "The given data was invalid.") {
                notification.error({
                    message:     reset.message,
                    description: reset.errors.token
                })
                setLoading(false)
            } else {
                setTimeout(
                    () => {
                        notification.info({
                            message: reset.message,
                        })
                        setRedirect(true)
                    },
                    1000
                )
            }
        } else {
            notification.error({
                message: "Er ging iets fout, probeer nogmaals alstublieft.",
            })
            setLoading(false)
        }
    }
    
    return redirect ? <RedirectToLogin pathAfterLogin={'/tyre'}/> : <Form<setNewPasswordForm>
        name="newPasswordForm"
        onFinish={(values) => submit(values, token)}
        labelCol={{span: labelColSpan}}
        wrapperCol={{span: 24 - labelColSpan}}
        style={{
            maxWidth: 450,
            width:    'calc(100vw - 60px)',
        }}
    >
        <Form.Item
            label="Nieuw wachtwoord"
            name="password"
            rules={[
                {required: true, message: 'Dit veld is verplicht.'},
                {pattern: /(?=.*?[A-Z])/, message: "Wachtwoord moet minimaal één hoofdletter bevatten"},
                {pattern: /(?=.*?[a-z])/, message: "Wachtwoord moet minimaal één kleine letter bevatten"},
                {pattern: /(?=.*?[0-9])/, message: "Wachtwoord moet minimaal één cijfer bevatten"},
                {
                    pattern: /(?=.*?[#?!@$%^&*-])/,
                    message: "Wachtwoord moet minimaal één speciaal teken bevatten"
                },
                {min: 8, message: "Wachtwoord moet minimaal 8 tekens lang zijn"}
                //Minimum of 8 characters. 1 capital character, 1 lowercase, 1 digit, and 1 symbol
            ]}
        >
            <Input.Password/>
        </Form.Item>
        <Form.Item
            label="Wachtwoord nogmaals"
            name="password_again"
            rules={[{required: true, message: 'Verplicht veld'}]}
        >
            <Input.Password/>
        </Form.Item>
        <Form.Item wrapperCol={{offset: labelColSpan, span: 24 - labelColSpan}}>
            <Button loading={loading} type="primary" htmlType="submit">
                Stel wachtwoord in
            </Button>
        </Form.Item>
    </Form>
    
};