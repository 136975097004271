import React from "react";
import {Button, notification, Popconfirm} from "antd";
import {UndoOutlined} from "@ant-design/icons";
import {useFetch} from "use-http";
import {apiEndpoint, getDefaultHeaders} from "../common/data";
import {apiModels} from "../common/types";

export const ConnectedRestoreButton: React.FC<{
    model: apiModels,
    rowId: number,
    reload: () => void,
}> = ({model, rowId, reload}) => {
    
    const BASE_URL = `${apiEndpoint}/${model}/${rowId}/restore`
    
    const options: any = {
        cachePolicy: 'no-cache',
        headers:     getDefaultHeaders()
    }
    
    const {put, response} = useFetch(BASE_URL, options);
    
    function doGoodResponse() {
        notification.success({message: "record hersteld"});
        reload();
    }
    
    function doBadResponse() {
        notification.error({message: response.statusText});
    }
    
    const handleOk = async () => {
        await put()
        response.ok ? doGoodResponse() : doBadResponse()
    }
    
    return <Popconfirm
        title="Herstel?"
        onConfirm={handleOk}
    >
        <Button style={{marginLeft: 'auto', display: 'block'}}>
            <UndoOutlined style={{color: "#1890ff"}}/>
        </Button>
    </Popconfirm>
    
};