import React from "react";
import {Select} from "antd";

export const CustomMultiSelect: React.FC<{
    values: string[]
    onChange: (value: any) => void
    defaultValues: string[]
    maxCount: number
    editing: boolean
    setEditing: (value: boolean) => void
}> = ({values, onChange, defaultValues, maxCount, editing, setEditing}) => {
    
    const {Option} = Select;
    
    const options: any = [];
    
    values?.forEach(value => {
            options.push(<Option value={value.toString()} key={value.toString()}>{value.toString()}</Option>)
        }
    )
    
    return <Select
        disabled={!editing}
        bordered={editing}
        mode="multiple"
        style={{width: '100%'}}
        placeholder="Selecteer"
        defaultValue={defaultValues}
        onBlur={() => setEditing(false)}
        onChange={(value) => onChange(value)}
        maxTagCount={maxCount}
    >
        {options}
    </Select>
};