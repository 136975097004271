import React, {useState} from "react";
import {Button, Form, Input, notification} from "antd";
import {apiEndpoint} from "../common/data";
import useFetch from "use-http";
import {RedirectToLogin} from "../RedirectToLogin";

const labelColSpan = 7;

type PasswordResetForm = {
    email: string
}

export const ResetPasswordForm: React.FC = () => {
    
    const [loading, setLoading]   = useState<boolean>(false)
    const [redirect, setRedirect] = useState<boolean>(false)
    
    const {post} = useFetch(apiEndpoint + '/auth/passwordReset');
    
    async function submit(payload: PasswordResetForm) {
        setLoading(true);
        const reset: {
            access_token: string,
            expires_in: number,
        } | {
            message: string,
        } = await post({...payload, bust_cache: Date.now()});
        if ('message' in reset) {
            setTimeout(
                () => {
                    notification.success({
                        message: reset.message,
                    })
                    setRedirect(true)
                }, 1000
            )
        } else {
            notification.error({
                message:     'Er ging iets fout.',
                description: 'Probeer het nogmaals, of neem contact op met de administrator.'
            })
            setLoading(false)
        }
    }
    
    if (redirect)
        return <RedirectToLogin pathAfterLogin={'/tyre'}/>;
    
    return <>
        <p style={{paddingLeft: 65, width: 480, paddingBottom: 10}}>
            Wachtwoord vergeten? <br/>
            Vul hier uw e-mail in, dan sturen wij u instructies om uw wachtwoord te resetten.
        </p>
        <Form<PasswordResetForm>
            name="resetForm"
            onFinish={(values) => submit(values)}
            labelCol={{span: labelColSpan}}
            wrapperCol={{span: 24 - labelColSpan}}
            style={{
                maxWidth: 450,
                width:    'calc(100vw - 60px)',
            }}
        >
            <Form.Item
                label="E-mail"
                name="email"
                rules={[{required: true, message: 'Dit veld is verplicht'}]}
            >
                <Input/>
            </Form.Item>
            <Form.Item wrapperCol={{offset: labelColSpan, span: 24 - labelColSpan}}>
                <Button loading={loading} disabled={loading} type="primary" htmlType="submit">
                    Reset wachtwoord
                </Button>
            </Form.Item>
        </Form>
    </>
};